import React, { useEffect } from "react";
import styles from "project-name/css/admin/adminLayout.module.css";
import HorizontalNavbar from "../Global/Navbar/HorizontalNavbar";
import Breadcrumb from "project-name/components/CandidateSecion/Documents/Breadcrumb";
import CandidateTable from "./CandidateTable";
import { fetchAdminProfile } from "project-name/Redux/features/adminProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "project-name/Redux/Store";

const CandidateSection: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  // **Fetch Filtered Profiles from Redux**
  const filteredProfiles = useSelector(
    (state: RootState) => state.adminProfile.filteredProfiles
  );

  useEffect(() => {
    dispatch(fetchAdminProfile());
  }, [dispatch], [filteredProfiles]);

  return (
    <div data-testid="candidate-section">
      <div className={styles.mDiv} data-testid="candidate-list">
        <HorizontalNavbar />
        <div style={{ padding: "40px" }}>
          <Breadcrumb
            bread1="Home"
            bread2="Candidate profile"
            link1="/admin-home"
          />
        </div>

        {/* Pass `filteredProfiles` to CandidateTable */}
        <CandidateTable profiles={filteredProfiles} />
      </div>
    </div>
  );
};

export default CandidateSection;
