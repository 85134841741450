import React from 'react';
import { Viewer, Worker, Plugin, RenderViewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { OpenFile } from '@react-pdf-viewer/core';

interface Props {
    onClose: () => void;
    base64: string; // Pass the base64 string for the PDF
    fileName: string; // Pass the file name for the PDF
}

// Custom Plugin to Show Only First Page
const pageThumbnailPlugin = (PageThumbnail: React.ReactElement): Plugin => {
    return {
        renderViewer: (renderProps: RenderViewer) => {
            let { slot } = renderProps;

            // Replace the default viewer layout with the single page component
            slot.children = PageThumbnail;

            // Reset subSlot to prevent rendering other pages
            slot.subSlot.attrs = {};
            slot.subSlot.children = <></>;

            return slot;
        },
    };
};

// Component for Displaying Only the First Page
const PageThumbnail = ({ fileUrl }: { fileUrl: string }) => {
    return (
        <div style={{ border: '1px solid #ccc', width: '278px', height: '230px' }}>
            <Viewer fileUrl={fileUrl} defaultScale={.5} initialPage={0} />
        </div>
    );
};

// Main PDF View Component
const PDFview: React.FC<Props> = ({ onClose, base64, fileName }) => {
    const pdfjsVersion = '3.4.120';
    const workerUrl = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;

    // Create plugin instance
    const firstPagePlugin = pageThumbnailPlugin(
        <PageThumbnail fileUrl={`data:application/pdf;base64,${base64}`} />
    );
    const getFilePluginInstance = getFilePlugin({
      fileNameGenerator: (file: OpenFile) => {
        // `file.name` is the URL of opened file]
        return fileName;
    },
    });

    return (
        <div className="viewSourcePopup">
            <div className="viewSourcePopupContentpdf">
                <Worker workerUrl={workerUrl}>
                <div
                    style={{
                      alignItems: 'center',
                      backgroundColor: '#eeeeee',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
                      display: 'flex',
                      padding: '4px',
                    }}
                  >
                    <getFilePluginInstance.DownloadButton />
                  </div>
                    <Viewer fileUrl={`data:application/pdf;base64,${base64}`} plugins={[firstPagePlugin]} />
                </Worker>
            </div>
        </div>
    );
};

export default PDFview;
