import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './components/AuthContext/AuthContext';

interface ProtectedRouteProps {
  element: React.ComponentType;
  persona?: ('admin' | 'user' | 'recruiter')[] | 'admin' | 'user' | 'recruiter';
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element: Component, persona }) => {
  const { authenticated } = useContext(AuthContext) as { authenticated: boolean } || { authenticated: null };

  if (authenticated === null) {
    return <div>Loading...</div>;
  }

  const userType = localStorage.getItem('userName');

  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  // Convert single persona to array for consistent handling
  const allowedPersonas = Array.isArray(persona) ? persona : [persona];

  // Check if user's type is included in allowed personas
  if (!allowedPersonas.includes(userType as any)) {
    switch (userType) {
      case 'user':
        return <Navigate to="/" />;
      case 'admin':
        return <Navigate to="/admin-home" />;
      case 'recruiter':
        return <Navigate to="/recruiter-home" />;
      default:
        return <Navigate to="/unauthorized" />;
    }
  }

  return <Component />;
};

export default ProtectedRoute;