import React, { useState , useEffect} from 'react';
import { Box, Button ,  CircularProgress } from '@mui/material';
import styles from './FilterButton.module.css';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { RootState } from 'project-name/Redux/Store';
import {  useNavigate } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux';
import { AppDispatch } from 'project-name/Redux/Store'; // Adjust the import path as necessary
import { setFilters } from 'project-name/Redux/features/adminProfileSlice';
import { fetchSenseCandidates } from 'project-name/Redux/features/senseApiSlice';
import FileInput from 'project-name/components/CandidateSecion/Documents/CandidateForms/DocumentsUpload/GlobalFormsField/FileInput';
import * as XLSX from 'xlsx';
import { submitCandidateData } from 'project-name/Redux/features/createCandidateSlice';
import { toast } from 'react-toastify';


interface Tower {
  value: string;
  name: string;
}
interface Function {
  value: string;
  name: string;
}
interface FileInputData {
  bulkActionFile?: File | null;
}
interface CandidateRow {
  designation: string;
  employee_band: string;
  tower: string;
  first_name: string;
  last_name: string;
  full_name: string;
  gender: string;
  function: string;
  d_o_j: string;
  recruiter_email: string;
  hiring_manager: string;
  hiring_manager_email: string;
  pbp_name: string;
  pbp_email: string;
  position_id: string;
  email: string;
  candidate_type: string;
}
interface FilterState {
  towers: string[];
  functions: string[];
  bands: string[];
}
const FilterButton: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [showButton, setShowButton] = useState(true);
  const { loading} = useSelector((state: RootState) => state.senseCandidates);
  const navigate = useNavigate();
  
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [selectedTowers, setSelectedTowers] = useState<string[]>([]);
  const [selectedFunctions, setSelectedFunctions] = useState<string[]>([]);
  const [selectedBands, setSelectedBands] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [towers, setTowers] = useState<Tower[]>([
    { value: 'GAC', name: 'GAC' },
    { value: 'GBS', name: 'GBS' },
    { value: 'Global People Continuity', name: 'Global People Continuity' },
    { value: 'Operations', name: 'Operations' },
    { value: 'People', name: 'People' },
    { value: 'Technology', name: 'Technology' },
  ]);
  const [bands, setBands] = useState<Tower[]>([
    { value: 'I', name: 'I' },
    { value: 'II', name: 'II' },
    { value: 'III', name: 'III' },
    { value: 'IV', name: 'IV' },
    { value: 'V', name: 'V' },
    { value: 'VI', name: 'VI' },
    { value: 'VII', name: 'VII' },
    { value: 'VIII', name: 'VIII' },
    { value: 'IX', name: 'IX' },
  ]);
  const [functions, setFunctions] = useState<Function[]>([
    { value: 'People', name: 'People' },
    { value: 'GEN AI', name: 'GEN AI' },
    { value: 'Non-Commercial', name: 'Non-Commercial' },
    { value: 'Martech', name: 'Martech' },
    { value: 'Sales Distribution', name: 'Sales Distribution' },
    { value: 'Strategy & Transformation', name: 'Strategy & Transformation' },
    { value: 'Commercial', name: 'Commercial' },
    { value: 'Finance Analytics', name: 'Finance Analytics' },
    { value: 'BEES', name: 'BEES' },
    { value: 'GBS Commercial', name: 'GBS Commercial' },
    { value: 'GBS TECH', name: 'GBS TECH' },
    { value: 'People Continuity', name: 'People Continuity' },
    { value: 'Africa Finance Operations', name: 'Africa Finance Operations' },
    { value: 'CP&A', name: 'CP&A' },
    { value: 'FP&A', name: 'FP&A' },
    { value: 'Digital Solutions', name: 'Digital Solutions' },
    { value: 'PBS', name: 'PBS' },
    { value: 'EUR', name: 'EUR' },
    { value: 'Global Command Center', name: 'Global Command Center' },
    { value: 'GHQ', name: 'GHQ' },
    { value: 'NAZ', name: 'NAZ' },
    { value: 'OTC', name: 'OTC' },
    { value: 'Compliance', name: 'Compliance' },
    { value: 'Applications', name: 'Applications' },
    { value: 'People Transformation', name: 'People Transformation' },
    { value: 'DIGITAL WORKPLACE', name: 'DIGITAL WORKPLACE' },
    { value: 'Global DC & Cloud', name: 'Global DC & Cloud' },
    { value: 'Cyber Security & Network Operations', name: 'Cyber Security & Network Operations' },
    { value: 'Tech Logistics', name: 'Tech Logistics' },
    { value: 'Data & Architecture', name: 'Data & Architecture' },
    { value: 'Employee Experience', name: 'Employee Experience' },
  ]);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [profiledropdownOpen, setProfiledropdownOpen] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
    if (filter !== 'towers') {
      setSelectedTowers([]);
    }
  };
  useEffect(() => {
    const username = sessionStorage.getItem('persona');
    if (username === 'recruiter') {
      setShowButton(false);
    }
  }, []);
  const handleFetchSenseData = () => {
    
    dispatch(fetchSenseCandidates());
};
  const handleTowerChange = (towerValue: string) => {
    let updatedSelectedTowers: string[];

    if (towerValue === '') {
      if (selectedTowers.length === towers.length) {
        updatedSelectedTowers = [];
      } else {
        updatedSelectedTowers = towers.map(tower => tower.value);
      }
    } else {
      if (selectedTowers.includes(towerValue)) {
        updatedSelectedTowers = selectedTowers.filter(id => id !== towerValue);
      } else {
        updatedSelectedTowers = [...selectedTowers, towerValue];
      }
    }

    setSelectedTowers(updatedSelectedTowers);
  };
  const handleFunctionChange = (functionValue: string) => {
    let updatedSelectedFunctions: string[];

    if (functionValue === '') {
      if (selectedFunctions.length === functions.length) {
        updatedSelectedFunctions = [];
      } else {
        updatedSelectedFunctions = functions.map(tower => tower.value);
      }
    } else {
      if (selectedFunctions.includes(functionValue)) {
        updatedSelectedFunctions = selectedFunctions.filter(id => id !== functionValue);
      } else {
        updatedSelectedFunctions = [...selectedFunctions, functionValue];
      }
    }

    setSelectedFunctions(updatedSelectedFunctions);
  };
  const handleBandChange = (bandValue: string) => {
    let updatedSelectedbands: string[];

    if (bandValue === '') {
      if (selectedBands.length === bands.length) {
        updatedSelectedbands = [];
      } else {
        updatedSelectedbands = bands.map(tower => tower.value);
      }
    } else {
      if (selectedBands.includes(bandValue)) {
        updatedSelectedbands = selectedBands.filter(id => id !== bandValue);
      } else {
        updatedSelectedbands = [...selectedBands, bandValue];
      }
    }

    setSelectedBands(updatedSelectedbands);
  };

  const toggleDropdown = (type : string) => {
    if (type === 'Profile') {
      setProfiledropdownOpen(!profiledropdownOpen);
    }else{
      setDropdownOpen(!dropdownOpen);
    }
    if (type === 'Flter') {
      if (dropdownOpen) {
        setAppliedFilters([]);
        setDropdownOpen(false);
      } else {
        setDropdownOpen(true);
      }
    }
  };

  const [fileInputData, setFileInputData] = useState<FileInputData>({
    bulkActionFile: null
  });
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileInputData({ bulkActionFile: file });
    }
  };

  const uploadFile = async () => {
    if (!fileInputData.bulkActionFile) return;
  
    setIsUploading(true); // Show loader
  
    try {
      const file = fileInputData.bulkActionFile;
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet: CandidateRow[] = workbook.Sheets[sheetName] !== undefined 
        ? XLSX.utils.sheet_to_json<CandidateRow>(workbook.Sheets[sheetName]) 
        : [];
  
      const candidates = worksheet.map(row => ({
        designation: row.designation,
        employee_band: row.employee_band,
        tower: row.tower,
        first_name: row.first_name,
        last_name: row.last_name,
        full_name: row.full_name,
        gender: row.gender,
        function: row.function,
        d_o_j: row.d_o_j,
        recruiter_email: row.recruiter_email,
        hiring_manager: row.hiring_manager,
        hiring_manager_email: row.hiring_manager_email,
        pbp_name: row.pbp_name,
        pbp_email: row.pbp_email,
        position_id: row.position_id,
        candidate_type: row.candidate_type,
        email: row.email
      }));
  
      const payload = { candidates };
      console.log('Payload:', payload);
  
      
  
      const formData = new FormData();
      formData.append('candidates', JSON.stringify(candidates));
  
      const response = await dispatch(submitCandidateData(payload));
      // alert('Candidates uploaded successfully!'); // Show success message
      if (submitCandidateData.fulfilled.match(response)) {
        toast.success('Candidate added successfully');
        setProfiledropdownOpen(false);
        navigate('/candidate');
      } else {
        // Handle any errors returned from the thunk
        toast.error('Failed to add candidate');
        navigate('/candidate');
      }
      console.log('Candidates uploaded successfully:', response);
    } catch (error) {
      console.error('Error reading or uploading file:', error);
    } finally {
      setIsUploading(false);
    }
  };
  const applyFilters = () => {
    let filtersToAdd: string[] = [];
    if (selectedFilter === 'towers') {
      if (selectedTowers.length === towers.length) {
        filtersToAdd.push("All towers");
      } else {
        const selectedTowerNames = selectedTowers.map((towerValue) =>
          towers.find((tower) => tower.value === towerValue)?.name || ''
        );
        filtersToAdd = [...new Set([...appliedFilters, ...selectedTowerNames])];
      }

      if (!appliedFilters.includes("Towers")) {
        filtersToAdd.push("Towers");
      }
    }
    setAppliedFilters(filtersToAdd);
    setDropdownOpen(false);
    const filters: FilterState = {
      towers: selectedTowers, // Apply selected towers
      functions: selectedFunctions,  // You can extend this if needed
      bands: selectedBands       // You can extend this if needed
  };

  console.log("Dispatching Filters:", filters);

  dispatch(setFilters(filters)); 
  };
  const addCandidate = ()=>{
    navigate('/add-candidate')
  }

  return (
    <>
    
    <div className={styles.btnDiv}>
      <div>
      {showButton && (
      <Button  color="primary"
            variant="contained"
            sx={{ width:"170px", textTransform: 'capitalize', fontWeight: '600' , py:"10px" }}  onClick={handleFetchSenseData}
            disabled={loading}
            > 
            {loading ? <CircularProgress size={24} sx={{color:"white"}} /> : 'Fetch Sense Data'}</Button>
             )}
      </div>
      <div className={styles.dropdown}>
        <Button variant="outlined" className={styles.btn} onClick={() => toggleDropdown('Flter')} data-testid="filter-button" sx={{fontSize: {
          xs: '10px', 
          sm: '11px', 
          md: '12px', 
          lg: '13px', 
        },
          
        cursor:"pointer"}}>
          + add filter
        </Button>
       
        {appliedFilters.length > 0 && (
          <div className={styles.filterDiv}>
            <p style={{color:"black"}}>x</p>
            {/* <div className={styles.appliedFilters}>
              <span>{appliedFilters.join(', ')}</span>
            </div> */}
          </div>
        )}
        {dropdownOpen && (
          <div className={styles.dropdownContent}>
            <div className={styles.subContent} onClick={() => handleFilterChange('towers')}>
              <p>Towers </p>
              <FaAngleRight />
            </div>
            <div className={styles.subContent} onClick={() => handleFilterChange('functions')}>
              <p>Functions </p>
              <FaAngleRight />
            </div>
            <div className={styles.subContent} onClick={() => handleFilterChange('bands')}>
              <p>Band </p>
              <FaAngleRight />
            </div>
            <div className={styles.subContentt} >
                <Button color="primary" variant="contained" className={styles.btn} onClick={applyFilters}>
                  Apply filter
                </Button>
            </div>
            

            {selectedFilter === 'towers' && (
              <div className={styles.subdropDown}>
                <FormGroup sx={{ padding: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedTowers.length === towers.length}
                        onChange={() => handleTowerChange('')}
                      />
                    }
                    label="All Towers"
                  />
                  {towers.map((tower) => (
                    <FormControlLabel
                      key={tower.value}
                      control={<Checkbox checked={selectedTowers.includes(tower.value)} />}
                      label={tower.name}
                      onChange={() => handleTowerChange(tower.value)}
                      data-testid={`tower-checkbox-${tower.value}`}
                    />
                  ))}
                  {/* <Button color="primary" variant="contained" className={styles.btn} onClick={applyFilters} >
                    Apply filter
                  </Button> */}
                </FormGroup>
              </div>
            )}
            {selectedFilter === 'functions' && (
              <div className={styles.subdropDown}>
              <FormGroup sx={{ padding: '10px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFunctions.length === functions.length}
                      onChange={() => handleFunctionChange('')}
                    />
                  }
                  label="All Functions"
                />
                {functions.map((f) => (
                  <FormControlLabel
                    key={f.value}
                    control={<Checkbox checked={selectedFunctions.includes(f.value)} />}
                    label={f.name}
                    onChange={() => handleFunctionChange(f.value)}
                    data-testid={`tower-checkbox-${f.value}`}
                  />
                ))}
                
              </FormGroup>
            </div>
            )}
            {selectedFilter === 'bands' && (
              <div className={styles.subdropDown}>
                <FormGroup sx={{ padding: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedBands.length === bands.length}
                        onChange={() => handleBandChange('')}
                      />
                    }
                    label="All Bands"
                  />
                  {bands.map((band) => (
                    <FormControlLabel
                      key={band.value}
                      control={<Checkbox checked={selectedBands.includes(band.value)} />}
                      label={band.name}
                      onChange={() => handleBandChange(band.value)}
                      data-testid={`tower-checkbox-${band.value}`}
                    />
                  ))}
                  {/* <Button color="primary" variant="contained" className={styles.btn} onClick={applyFilters} >
                    Apply filter
                  </Button> */}
                </FormGroup>
              </div>
            )}
          </div>
        )}
      </div>

      <Button variant="outlined" className={styles.btn} onClick={addCandidate} sx={{fontSize: {
          xs: '10px', 
          sm: '11px', 
          md: '12px', 
          lg: '13px', 
        },   
        cursor:"pointer"}}>
        + Add candidate
      </Button>
      <Button color="primary" variant="contained" className={styles.btn} onClick={() => toggleDropdown('Profile')} sx={{fontSize: {
          xs: '10px', 
          sm: '11px', 
          md: '12px', 
          lg: '13px', 
        },
        
          
        cursor:"pointer",}}>
        Bulk action <FaAngleDown style={{ fontSize: '22px' }} />
      </Button>
      {profiledropdownOpen && (
          <div className={styles.dropdownBulk}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginTop: "20px" }}>
              <FileInput
                label="Please Upload Excel File For Bulk Action"
                name="bulkFile"
                file={fileInputData.bulkActionFile}
                onChange={handleFileInputChange}
                id="adhaarCardInput"
                fileCss1="docDiv1"
                fileCss2="label"
                fileCss3="fileDiv"
                fileCss4="fileDiv1"
                readOnly={false}
                customSx={{ flexGrow: 1 }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  py: "15px",
                  fontSize: "12px",
                  borderRadius: "10px",
                  px: "50px",
                }}
                onClick={uploadFile}
              >
              {isUploading ? <CircularProgress size={20} sx={{ marginRight: 1, color: "white" }} /> : "Upload File"}
              </Button>
            </Box>
            
          </div>
            )}
      <Button variant="outlined" className={styles.btn} sx={{fontSize: {
          xs: '10px', 
          sm: '11px', 
          md: '12px', 
          lg: '13px', 
        },
        
        cursor:"pointer",}}>
        All profiles <FaAngleDown style={{ fontSize: '22px' }} />{' '}
      </Button>
    </div>
    </>
  );
};

export default FilterButton;
