import React, { useState, FormEvent, useRef, useEffect } from 'react';
import styles from '../Documents.module.css';
import { Button, SelectChangeEvent } from '@mui/material';
import { toast } from 'react-toastify';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


import SubmitPopup from 'project-name/components/CandidateSecion/Global/Popup/SubmitPopup';

import FileInput from '../GlobalFormsField/FileInput';
import FormField from '../GlobalFormsField/FormField';
import FormSelect from '../GlobalFormsField/FormSelect';
import Modal from '@mui/material/Modal';
import apiClient from 'project-name/api/apiClient';
import { cities, countries, union, validatePhoneNumber } from 'project-name/util/util';
import PhoneMenu from 'project-name/components/CandidateSecion/Global/Navbar/phoneMenu';
import AutocompleteInput from '../GlobalFormsField/AutoCompleteInput';
import { useLocation } from 'react-router-dom';

interface WorkExperienceFormProps {
  onSubmit?: (formData: any) => void;
  persona?: string;
  formData?: any;
}
interface FormData {
  organization_name: string;

  currently_working: string;
  start_date: Dayjs | string;
  end_date: Dayjs | string;
  tenure: string;
  reason_for_leaving: string;
  hr_consent: string;
  is_fresher: string;
  street_address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  company_contact_number: string;
  employee_id: string;
  designation: string;
  supervisor_name: string;
  prev_supervisor_name: string;
  prev_supervisor_name2: string;
  supervisor_phone_number: string;
  prev_supervisor_phone_number: string;
  prev_supervisor_phone_number2: string;
  supervisor_email: string;
  hr_name: string;
  prev_hr_name: string;
  prev_hr_name2: string;
  prev_hr_phone_number: string;
  prev_hr_phone_number2: string;
  hr_phone_number: string;
  hr_email: string;
  prev_hr_email: string;
  prev_hr_email2: string;
  gap_details: string;

  uan_no: string;
  prev_employment: string;
  prev_employment2: string;
  prev_supervisor_email: string;
  prev_supervisor_email2: string;
  prev_start_date: Dayjs | string;
  prev_start_date2: Dayjs | string;
  prev_end_date: Dayjs | string;
  prev_end_date2: Dayjs | string;
  prev_employee_id: string;
  prev_employee_id2: string;
  prev_organization_name: string;
  prev_organization_name2: string;
  prev_city: string;
  prev_city2: string;
  prev_pin: string;
  prev_pin2: string;
  prev_company_name: string;
  prev_company_name2: string;
  prev_designation: string;
  prev_designation2: string;
  prev_country: string;
  prev_country2: string;
  prev_state: string;
  prev_state2: string;
  prev_experienced_doc_type: string;
  prev_experienced_doc_type2: string;
  referee_name: string
  referee_name2: string
  referee_contact2: string;
  referee_contact: string;
  referee_email2: string;
  referee_email: string;
  referee_university_name2: string;
  referee_university_name: string;
  referee_company_name2: string;
  referee_company_name: string;
}

interface FileInputData {
  paySlipFile?: File | null;
  resumeFile?: File | null;
  relLetterFile?: File | null;
  service_doc?: File | null;
  service_doc2?: File | null;
}

const WorkExperienceForm: React.FC<WorkExperienceFormProps> = ({ persona, formData: form }) => {
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(() => persona === 'admin' ? false : true);
  const resumeInputRef = useRef<HTMLInputElement | null>(null);
  const paySlipInputRef = useRef<HTMLInputElement | null>(null);
  const relLetterInputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const [user_id, setUserId] = useState<number>(() => persona === 'admin' ? location.state.user_id : 0);
  const [formData, setFormData] = useState<FormData>({
    organization_name: '',
    referee_name: '',
    referee_name2: '',
    currently_working: '',
    start_date: dayjs(),
    end_date: dayjs(),
    tenure: '',
    hr_consent: '',
    reason_for_leaving: '',
    street_address: '',
    city: '',
    state: '',
    is_fresher: '',
    country: '',
    pincode: '',
    company_contact_number: '',
    employee_id: '',
    designation: '',
    supervisor_name: '',
    prev_supervisor_name: '',
    prev_supervisor_name2: '',
    prev_supervisor_email: '',
    prev_supervisor_email2: '',
    supervisor_phone_number: '',
    prev_supervisor_phone_number: '',
    prev_supervisor_phone_number2: '',
    supervisor_email: '',
    hr_name: '',
    prev_hr_name: '',
    prev_hr_name2: '',
    prev_hr_phone_number: '',
    prev_hr_phone_number2: '',
    hr_phone_number: '',
    hr_email: '',
    prev_hr_email: '',
    prev_hr_email2: '',
    gap_details: '',
    prev_employment: '',
    prev_employment2: '',
    uan_no: '',

    prev_start_date: dayjs(),
    prev_start_date2: dayjs(),
    prev_end_date: dayjs(),
    prev_end_date2: dayjs(),
    prev_employee_id: '',
    prev_employee_id2: '',
    prev_organization_name: '',
    prev_organization_name2: '',
    prev_city: '',
    prev_city2: '',
    prev_pin: '',
    prev_pin2: '',
    prev_company_name: '',
    prev_company_name2: '',
    prev_designation: '',
    prev_designation2: '',
    prev_country: '',
    prev_country2: '',
    prev_state: '',
    prev_state2: '',
    prev_experienced_doc_type: '',
    prev_experienced_doc_type2: '',
    referee_contact: "",
    referee_contact2: "",
    referee_email: "",
    referee_email2: "",
    referee_university_name2: "",
    referee_university_name: "",
    referee_company_name2: "",
    referee_company_name: ""
  });


  // handling form validations and errors

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({
    organization_name: '',

    currently_working: '',
    start_date: dayjs(),
    end_date: dayjs(),
    tenure: '',
    reason_for_leaving: '',
    street_address: '',
    is_fresher: '',
    city: '',
    state: '',
    // hr_consent: '',
    country: '',
    pincode: '',
    company_contact_number: '',
    employee_id: '',
    designation: '',
    supervisor_name: '',
    prev_supervisor_name: '',
    prev_supervisor_name2: '',
    prev_supervisor_email: '',
    prev_supervisor_email2: '',
    supervisor_phone_number: '',
    prev_supervisor_phone_number: '',
    prev_supervisor_phone_number2: '',
    supervisor_email: '',
    hr_name: '',
    prev_hr_name: '',
    prev_hr_name2: '',
    prev_hr_phone_number: '',
    prev_hr_phone_number2: '',
    hr_phone_number: '',
    hr_email: '',
    prev_hr_email: '',
    prev_hr_email2: '',
    gap_details: '',
    referee_name: '',
    referee_contact: "",
    referee_email: '',
    referee_university_name: '',
    prev_employment: '',
    prev_employment2: '',
    uan_no: '',
    prev_start_date: dayjs(),
    prev_start_date2: dayjs(),
    prev_end_date: dayjs(),
    prev_end_date2: dayjs(),
    prev_employee_id: '',
    prev_employee_id2: '',
    prev_organization_name: '',
    prev_organization_name2: '',
    prev_city: '',
    prev_city2: '',
    prev_pin: '',
    prev_pin2: '',
    prev_company_name: '',
    prev_company_name2: '',
    prev_designation: '',
    prev_designation2: '',
    prev_country: '',
    prev_country2: '',
    prev_state: '',
    prev_state2: '',
    prev_experienced_doc_type: '',
    prev_experienced_doc_type2: '',
    referee_company_name: ""
  });


  const toggleEditMode = (e: any) => {
 
      setIsEditMode((prevMode) => !prevMode);

    e.preventDefault();
  };
  useEffect(() => {
    if (persona === 'admin' && form !== undefined) {
      const sanitizedForm = { ...form };
      for (const key in sanitizedForm) {
        if (sanitizedForm[key] === null || sanitizedForm[key] === undefined) {
          sanitizedForm[key] = '';
        }
      }
      setFormData(sanitizedForm);
    }
  }, [persona, form])
  // Example form submission handling
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formValid = validateForm();



    if (formValid) {
      try {
        // console.log('Form submitted:', formData, 'persona', persona);
        const formDataToSend = new FormData();
        // Append files to formData

        if (formData.is_fresher === 'yes') {
          if (!fileInputData.resumeFile) {
            alert('Please upload resume');
            return;
          }
        } else {
          if (!fileInputData.paySlipFile) {
            alert('Please upload payslip');
            return;
          }
          if (!fileInputData.relLetterFile) {
            alert('Please upload relieving letter');
            return;
          }
          if (formData.prev_employment === "true" && !fileInputData.service_doc) {
            alert(formData.prev_experienced_doc_type === "Experience Letter" ? "Please upload Experience Letter" : "Please upload Service Letter");
            return;
          }
          if (formData.prev_employment2 === "true" && !fileInputData.service_doc2) {
            alert(formData.prev_experienced_doc_type2 === "Experience Letter" ? "Please upload Experience Letter" : "Please upload Service Letter");
            return;
          }

        }

        // Append paySlipFile to formData
        if (fileInputData.paySlipFile) {
          formDataToSend.append('pay_slip', fileInputData.paySlipFile);
        }

        if (fileInputData.resumeFile) {
          formDataToSend.append('resume', fileInputData.resumeFile);
        }
        if (fileInputData.relLetterFile) {
          formDataToSend.append('relieving_letter', fileInputData.relLetterFile);
        }
        if (fileInputData.service_doc) {
          formDataToSend.append('service_doc', fileInputData.service_doc);
        }
        if (fileInputData.service_doc2) {
          formDataToSend.append('service_doc2', fileInputData.service_doc2);
        }

        const formattedStartDate = dayjs(formData.start_date).format('YYYY-MM-DDTHH:mm:ss');
        const formattedEndDate = dayjs(formData.end_date).format('YYYY-MM-DDTHH:mm:ss');
        if (persona === 'admin') {
          console.log('admin');
          const is_edit = Object.keys(form).length !== 0;
          formDataToSend.append('data', JSON.stringify({
            organization_name: formData.organization_name,

            currently_working: formData.currently_working,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            tenure: formData.tenure,
            reason_for_leaving: formData.reason_for_leaving,
            street_address: formData.street_address,
            hr_consent: formData.hr_consent,
            is_fresher: formData.is_fresher,
            city: formData.city,
            state: formData.state,
            country: formData.country,
            pincode: formData.pincode,
            company_contact_number: formData.company_contact_number,
            employee_id: formData.employee_id,
            designation: formData.designation,
            supervisor_name: formData.supervisor_name,
            prev_supervisor_name: formData.prev_supervisor_name,
            prev_supervisor_name2: formData.prev_supervisor_name2,
            supervisor_phone_number: formData.supervisor_phone_number,
            prev_supervisor_phone_number: formData.prev_supervisor_phone_number,
            prev_supervisor_phone_number2: formData.prev_supervisor_phone_number2,
            supervisor_email: formData.supervisor_email,
            prev_supervisor_email: formData.prev_supervisor_email,
            prev_supervisor_email2: formData.prev_supervisor_email2,
            hr_name: formData.hr_name,
            prev_hr_name: formData.prev_hr_name,
            prev_hr_name2: formData.prev_hr_name2,
            hr_phone_number: formData.hr_phone_number,
            prev_hr_phone_number: formData.prev_hr_phone_number,
            prev_hr_phone_number2: formData.prev_hr_phone_number2,
            hr_email: formData.hr_email,
            prev_hr_email: formData.prev_hr_email,
            prev_hr_email2: formData.prev_hr_email2,
            gap_details: formData.gap_details,

            uan_no: formData.uan_no,
            prev_employment: formData.prev_employment,
            prev_employment2: formData.prev_employment2,
            prev_start_date: formData.prev_start_date,
            prev_start_date2: formData.prev_start_date2,
            prev_end_date: formData.prev_end_date,
            prev_end_date2: formData.prev_end_date2,
            prev_employee_id: formData.prev_employee_id,
            prev_employee_id2: formData.prev_employee_id2,
            prev_organization_name: formData.prev_organization_name,
            prev_organization_name2: formData.prev_organization_name2,
            prev_city: formData.prev_city,
            prev_city2: formData.prev_city2,
            prev_pin: formData.prev_pin,
            prev_pin2: formData.prev_pin2,
            prev_company_name: formData.prev_company_name,
            prev_company_name2: formData.prev_company_name2,
            prev_designation: formData.prev_designation,
            prev_designation2: formData.prev_designation2,
            prev_country: formData.prev_country,
            prev_country2: formData.prev_country2,
            prev_state: formData.prev_state,
            prev_state2: formData.prev_state2,
            prev_experienced_doc_type: formData.prev_experienced_doc_type,
            prev_experienced_doc_type2: formData.prev_experienced_doc_type2,
            referee_name: formData.referee_name,
            referee_name2: formData.referee_name2,
            referee_contact: formData.referee_contact,
            referee_contact2: formData.referee_contact2,
            referee_email: formData.referee_email,
            referee_email2: formData.referee_email2,
            referee_university_name: formData.referee_university_name,
            referee_university_name2: formData.referee_university_name2,
            referee_company_name: formData.referee_company_name,
            referee_company_name2: formData.referee_company_name2,
           
          }))
          formDataToSend.append('type', 'work');
          formDataToSend.append('is_edit', (is_edit).toString());
          if (persona === 'admin') {
          try {
            const response = await apiClient.post(
              `/api/admin/${user_id}/update-additional-candidate-details`,
              formDataToSend,
              {
                withCredentials: true,
              }
            );

            if (response.status !== 200) {
              throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
            }

            const data = response.data;
            setShowModal(true);
            setIsEditMode((prevMode) => !prevMode);
          } catch (error: any) {
            console.error(error);
            toast.error("Failed to submit form");
          }
        }
        
        
        }
          // console.log('formDataToSend', formDataToSend);
          formDataToSend.append(
            'details',
            JSON.stringify({
              organization_name: formData.organization_name,
  
              currently_working: formData.currently_working,
              start_date: formattedStartDate,
              end_date: formattedEndDate,
              tenure: formData.tenure,
              reason_for_leaving: formData.reason_for_leaving,
              street_address: formData.street_address,
              hr_consent: formData.hr_consent,
              is_fresher: formData.is_fresher,
              city: formData.city,
              state: formData.state,
              country: formData.country,
              pincode: formData.pincode,
              company_contact_number: formData.company_contact_number,
              employee_id: formData.employee_id,
              designation: formData.designation,
              supervisor_name: formData.supervisor_name,
              prev_supervisor_name: formData.prev_supervisor_name,
              prev_supervisor_name2: formData.prev_supervisor_name2,
              supervisor_phone_number: formData.supervisor_phone_number,
              prev_supervisor_phone_number: formData.prev_supervisor_phone_number,
              prev_supervisor_phone_number2: formData.prev_supervisor_phone_number2,
              supervisor_email: formData.supervisor_email,
              prev_supervisor_email: formData.prev_supervisor_email,
              prev_supervisor_email2: formData.prev_supervisor_email2,
              hr_name: formData.hr_name,
              prev_hr_name: formData.prev_hr_name,
              prev_hr_name2: formData.prev_hr_name2,
              hr_phone_number: formData.hr_phone_number,
              prev_hr_phone_number: formData.prev_hr_phone_number,
              prev_hr_phone_number2: formData.prev_hr_phone_number2,
              hr_email: formData.hr_email,
              prev_hr_email: formData.prev_hr_email,
              prev_hr_email2: formData.prev_hr_email2,
              gap_details: formData.gap_details,
  
              uan_no: formData.uan_no,
              prev_employment: formData.prev_employment,
              prev_employment2: formData.prev_employment2,
              prev_start_date: formData.prev_start_date,
              prev_start_date2: formData.prev_start_date2,
              prev_end_date: formData.prev_end_date,
              prev_end_date2: formData.prev_end_date2,
              prev_employee_id: formData.prev_employee_id,
              prev_employee_id2: formData.prev_employee_id2,
              prev_organization_name: formData.prev_organization_name,
              prev_organization_name2: formData.prev_organization_name2,
              prev_city: formData.prev_city,
              prev_city2: formData.prev_city2,
              prev_pin: formData.prev_pin,
              prev_pin2: formData.prev_pin2,
              prev_company_name: formData.prev_company_name,
              prev_company_name2: formData.prev_company_name2,
              prev_designation: formData.prev_designation,
              prev_designation2: formData.prev_designation2,
              prev_country: formData.prev_country,
              prev_country2: formData.prev_country2,
              prev_state: formData.prev_state,
              prev_state2: formData.prev_state2,
              prev_experienced_doc_type: formData.prev_experienced_doc_type,
              prev_experienced_doc_type2: formData.prev_experienced_doc_type2,
              referee_name: formData.referee_name,
              referee_name2: formData.referee_name2,
              referee_contact: formData.referee_contact,
              referee_contact2: formData.referee_contact2,
              referee_email: formData.referee_email,
              referee_email2: formData.referee_email2,
              referee_university_name: formData.referee_university_name,
              referee_university_name2: formData.referee_university_name2,
              referee_company_name: formData.referee_company_name,
              referee_company_name2: formData.referee_company_name2
            }),
          );
        // console.log('formData',formData);
        try {
          const response = await apiClient.post(
            `/api/candidate/upload-work-experience-details`,
            formDataToSend,
            {
              withCredentials: true,
            }
          );

          if (response.status !== 200) {
            throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
          }

          const data = response.data;
          setShowModal(true);

        }
        catch (error: any) {
          console.error(error);
          toast.error(`Failed to submit form: ${error.message}`);
        }
        // Simulated fetch request
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Simulated successful submission

        // Reset form data after successful submission
        setFormData({
          organization_name: '',

          currently_working: '',
          start_date: dayjs(),
          end_date: dayjs(),
          tenure: '',
          hr_consent: '',
          reason_for_leaving: '',
          street_address: '',
          city: '',
          is_fresher: '',
          state: '',
          country: '',
          pincode: '',
          company_contact_number: '',
          employee_id: '',
          designation: '',
          supervisor_name: '',
          prev_supervisor_name: '',
          prev_supervisor_name2: '',
          prev_supervisor_email: '',
          prev_supervisor_email2: '',
          supervisor_phone_number: '',
          prev_supervisor_phone_number: '',
          prev_supervisor_phone_number2: '',
          supervisor_email: '',
          hr_name: '',
          prev_hr_name: '',
          prev_hr_name2: '',
          prev_hr_phone_number: '',
          prev_hr_phone_number2: '',
          hr_phone_number: '',
          hr_email: '',
          prev_hr_email: '',
          prev_hr_email2: '',
          gap_details: '',
          prev_employment: '',
          prev_employment2: '',
          uan_no: '',

          prev_start_date: dayjs(),
          prev_start_date2: dayjs(),
          prev_end_date: dayjs(),
          prev_end_date2: dayjs(),
          prev_employee_id: '',
          prev_employee_id2: '',
          prev_organization_name: '',
          prev_organization_name2: '',
          prev_city: '',
          prev_city2: '',
          prev_pin: '',
          prev_pin2: '',
          prev_company_name: '',
          prev_company_name2: '',
          prev_designation: '',
          prev_designation2: '',
          prev_country: '',
          prev_country2: '',
          prev_state: '',
          prev_state2: '',
          prev_experienced_doc_type: '',
          prev_experienced_doc_type2: '',
          referee_name: '',
          referee_name2: '',
          referee_contact: '',
          referee_contact2: '',
          referee_email: '',
          referee_email2: '',
          referee_university_name: '',
          referee_university_name2: '',
          referee_company_name: "",
          referee_company_name2: ""
        });

        // reset file input field
        setFileInputData({
          paySlipFile: null,
          resumeFile: null,
          relLetterFile: null,
        });

        // Reset file input fields
        if (resumeInputRef.current) resumeInputRef.current.value = '';
        if (paySlipInputRef.current) paySlipInputRef.current.value = '';
        if (relLetterInputRef.current) relLetterInputRef.current.value = '';
      } catch (error) {
        console.error('Error submitting form:', error);
        if (persona === 'admin') {
          setIsEditMode((prevMode) => !prevMode);
        }
      }
    } else {
      console.log('Form validation failed');
    }
  };

  const validateForm = () => {
    let formValid = true;
    const errors: Partial<FormData> = {};

    const requiredFields: { field: keyof FormData; message: string }[] = [
      { field: 'organization_name', message: 'Organization name is required' },
      { field: 'is_fresher', message: 'This field is required' },
      { field: 'currently_working', message: 'This field is required' },
      // { field: 'tenure', message: 'Tenure is required' },
      { field: 'street_address', message: 'Street address is required' },
      { field: 'city', message: 'City is required' },
      { field: 'state', message: 'State is required' },
      { field: 'country', message: 'Country is required' },
      { field: 'pincode', message: 'Pincode is required' },
      { field: 'company_contact_number', message: 'Company contact number is required' },
      { field: 'employee_id', message: 'Employee ID is required' },
      { field: 'designation', message: 'Designation is required' },
      { field: 'supervisor_name', message: 'Supervisor name is required' },
      // { field: 'prev_supervisor_name', message: 'Supervisor name is required' },
      { field: 'supervisor_phone_number', message: 'Supervisor phone number is required' },
      // { field: 'prev_supervisor_phone_number', message: 'Supervisor phone number is required' },
      { field: 'supervisor_email', message: 'Supervisor email is required' },
      // { field: 'prev_supervisor_email', message: 'Supervisor email is required' },
      { field: 'hr_name', message: 'HR name is required' },
      // { field: 'prev_hr_name', message: 'HR name is required' },
      // { field: 'hr_consent', message: 'Consent is required' },
      { field: 'hr_phone_number', message: 'HR phone number is required' },
      // { field: 'prev_hr_phone_number', message: 'HR phone number is required' },
      { field: 'hr_email', message: 'HR email is required' },
      // { field: 'prev_hr_email', message: 'HR email is required' },
      { field: 'gap_details', message: 'Gap details are required' },
      { field: 'uan_no', message: 'EPF/UAN details are required' },
      // { field: 'prev_employment', message: 'Previous Employment details are required' },
      // { field: 'prev_employment2', message: 'Second Previous Employment details are required' },
      { field: 'referee_company_name', message: 'Company name is required' },
    ];
    

    const refereeFields: { field: keyof FormData; message: string }[] = [
      { field: 'referee_name', message: 'Referee name is required' },
      // { field: 'referee_name2', message: 'Referee name 2 is required' },
      { field: 'referee_contact', message: 'Contact details are required' },
      { field: 'referee_email', message: 'Email id  is required' },
      { field: 'referee_university_name', message: 'University name is required' },
     ]

    const prevEmploymentFields: { field: keyof FormData; message: string }[] = [
      { field: 'prev_employee_id', message: 'Previous Employee ID is required' },
      // { field: 'prev_organization_name', message: 'Previous Organization name is required' },
      { field: 'prev_city', message: 'Previous City is required' },
      { field: 'prev_pin', message: 'Previous Pincode is required' },
      { field: 'prev_company_name', message: 'Previous Organization name is required' },
      { field: 'prev_designation', message: 'Previous Designation is required' },
      { field: 'prev_country', message: 'Previous Country is required' },
      { field: 'prev_state', message: 'Previous State is required' },
      { field: 'prev_experienced_doc_type', message: 'Previous  Document Type is required' },
      { field: 'referee_company_name', message: 'Company name is required' },


    ];

    const isFresher = formData.is_fresher === 'yes' || formData.is_fresher === '';
    

    if (isFresher) {
      refereeFields.forEach(({ field, message }) => {
        const value = formData[field];
        if (typeof value === 'string' && !value.trim()) {
          errors[field] = message;
          formValid = false;
        }
      });
    }

    requiredFields.forEach(({ field, message }) => {
      if (isFresher && field !== 'is_fresher') {
        return;
      }
      const value = formData[field];
      if (typeof value === 'string') {
        if (!value.trim()) {
          errors[field] = message;
          formValid = false;
        } else if (field === 'company_contact_number') {
          const contactNumberError = validatePhoneNumber(value);
          if (contactNumberError) {
            errors[field] = contactNumberError;
            formValid = false;
          }
        } else if (field === 'hr_phone_number') {
          const contactNumberError = validatePhoneNumber(value);
          if (contactNumberError) {
            errors[field] = contactNumberError;
            formValid = false;
          }
        } else if (field === 'supervisor_phone_number') {
          const contactNumberError = validatePhoneNumber(value);
          if (contactNumberError) {
            errors[field] = contactNumberError;
            formValid = false;
          }
        }
      } else if (dayjs.isDayjs(value)) {
        if (!value.isValid()) {
          errors[field] = message;
          formValid = false;
        }
      }
    });
    if (formData.prev_employment === 'yes') {
      prevEmploymentFields.forEach(({ field, message }) => {
        const value = formData[field];
        if (typeof value === 'string' && !value.trim()) {
          errors[field] = message;
          formValid = false;
        }
      });
    }

    console.log(errors)

    setFormErrors(errors);
    return formValid;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
    newValue?: any
  ) => {
    if (typeof newValue === 'string' && 'target' in event) {
      // Cast event.target to the correct type
      const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
      const name = target.getAttribute('id')?.split('-')[0];
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          [name as string]: newValue, // or `newValue.value` depending on your Autocomplete setup
        };
        return newData;
      });
    }
    else if ('target' in event) {
      const { name, value } = event.target;
      setFormData((prevData) => {
        const newData = {
          ...prevData,
          [name]: value,
        };
        return newData;
      });
    }

  };

  const handleDateChange = (date: Dayjs | null, field: 'start_date' | 'end_date' | 'prev_start_date' | 'prev_end_date') => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: date || dayjs(), // Use current date as default if date is null
    }));
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'start_date');
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'end_date');
  };
  const handlePrevStartDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'prev_start_date');
  };

  const handlePrevEndDateChange = (date: Dayjs | null) => {
    handleDateChange(date, 'prev_end_date');
  };

  const [fileInputData, setFileInputData] = useState<FileInputData>({
    paySlipFile: null,
    resumeFile: null,
    relLetterFile: null,
    service_doc: null,
    service_doc2: null,
  });


  // Function to handle file input change
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    // console.log(files)

    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'pdf') {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'Payslip':
          setFileInputData((prevData) => ({
            ...prevData,
            paySlipFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'service_doc':
          setFileInputData((prevData) => ({
            ...prevData,
            service_doc: files[0] || null, // Set to null if no file selected
          }));
          break;
          case 'service_doc2':
          setFileInputData((prevData) => ({
            ...prevData,
            service_doc2: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'Resume':
          setFileInputData((prevData) => ({
            ...prevData,
            resumeFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'RelLetter':
          setFileInputData((prevData) => ({
            ...prevData,
            relLetterFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        default:
          break;
      }
    }
  };

  // submit popup
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const isFresher = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: `No`,
    },
  ];

  const docType = [
    {
      value: 'Experienced Letter',
      label: 'Experienced Letter',
    },
    {
      value: 'Service Letter',
      label: 'Service Letter',
    },
  ];
  const fullName = sessionStorage.getItem("candidateName")
  return (
    <>
      {/* Modal */}
      {showModal && (
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SubmitPopup handleClose={handleCloseModal} />
        </Modal>
      )}
      <p style={{ padding: '5px 40px' }}>
        All on-campus hires are categorized as freshers irrespective of their previous work experience
      </p>

      <form className={styles.mAttachDiv}>
        <div
          className={styles.fieldDiv}
          style={{ paddingBottom: '10px' }}
        >
          <FileInput
            label="Upload Resume"
           
            name={`${fullName} 's Resume`}
            file={fileInputData.resumeFile}
            onChange={handleFileInputChange}
            id="Resume"
            required
            fileCss1="subDiv2"
            fileCss2="label"
            fileCss3="fileDiv2"
            fileCss4="fileDiv1"
            readOnly={!isEditMode}

          />



        </div>
        <FormSelect
          label="Are you a fresher?"
          name="is_fresher"
          inputlabel="Select"
          value={formData.is_fresher}
          error={formErrors.is_fresher}
          handleChange={handleChange}
          options={isFresher}
          // selectCss1="subDiv2"
          selectCss2="label"
          selectCss3="textField2"
          readOnly={!isEditMode}
          required={true}
        />


        {formData.is_fresher === 'no' && (
          <>
            <div className={styles.fieldDiv} style={{ paddingBottom: '10px' }}>
              <FileInput
                label="Last 3 months payslips"
                name={`${fullName} 's Payslips`}
               
                file={fileInputData.paySlipFile}
                onChange={handleFileInputChange}
                id="Payslip"
                fileCss1="subDiv2"
                fileCss2="label"
                fileCss3="fileDiv2"
                fileCss4="fileDiv1"
                readOnly={!isEditMode}
                required={true}

              />

              <FileInput
                label="Relieving letter"
                name={`${fullName} 's Relieving letter`}
               
                file={fileInputData.relLetterFile}
                onChange={handleFileInputChange}
                id="RelLetter"
                fileCss1="subDiv2"
                fileCss2="label"
                fileCss3="fileDiv2"
                fileCss4="fileDiv1"
                readOnly={!isEditMode}
                required={true}
              />
            </div>
            <div
              className={styles.fieldDiv}

            >
              <div className={styles.hrDiv}>
                {/* <div >
                  <FormSelect
                    label="Do you consent for reaching out to previous HR for verification?"
                    name="hr_consent"
                    inputlabel="Select"
                    value={formData.hr_consent}
                    error={formErrors.hr_consent}
                    handleChange={handleChange}
                    options={isFresher}
                    selectCss1="subDiv2"
                    selectCss2="label"
                    selectCss3="textField1"
                    readOnly={!isEditMode}
                    required={true}

                  />
                </div> */}


                <FormSelect
                  label="Currently working here?"
                  name="currently_working"
                  inputlabel="Select"
                  value={formData.currently_working}
                  error={formErrors.currently_working}
                  handleChange={handleChange}
                  options={isFresher}
                  selectCss1="subDiv2"
                  selectCss2="label"
                  selectCss3="textField1"
                  readOnly={!isEditMode}
                  required={true}
                />
                {/* {reason for leaving} */}
                {
                  formData.currently_working === 'no' && (
                    <FormField
                      label="Reason for leaving"
                      name="reason_for_leaving"
                      type='text'
                      value={formData.reason_for_leaving}
                      onChange={handleChange}
                      error={formErrors.reason_for_leaving}
                      cssInput1="subDiv2"
                      cssInput2="label"
                      placeholder="Enter Details"
                      readOnly={!isEditMode}
                      required={false}
                    />
                  )
                }

                <div className={styles.subDiv} style={{ paddingBottom: '10px' }}>
                  <div>
                    <p className={styles.label}>
                      Date of Joining<span style={{ color: 'red' }}>*</span>
                    </p>
                    <div className={styles.textField}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div style={{ backgroundColor: '#fff' }}>
                            <DatePicker
                              name="start_date"
                              value={dayjs(formData.start_date)}
                              className="custom-date-picker"
                              onChange={handleStartDateChange}
                              readOnly={!isEditMode}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div>
                    <p className={styles.label}>
                      Date of Leaving<span style={{ color: 'red' }}>*</span>
                    </p>
                    <div className={styles.textField}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div style={{ backgroundColor: '#fff' }}>
                            <DatePicker
                              name="end_date"
                              value={dayjs(formData.end_date)}
                              className="custom-date-picker"
                              onChange={handleEndDateChange}
                              readOnly={!isEditMode}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>

                <FormField
                  label="Company Contact Number"
                  name="company_contact_number"
                  type='phone'
                  value={formData.company_contact_number}
                  onChange={handleChange}
                  error={formErrors.company_contact_number}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />

                <FormField
                  label="Employee ID"
                  name="employee_id"
                  type='text'
                  value={formData.employee_id}
                  onChange={handleChange}
                  error={formErrors.employee_id}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />

                <FormField
                  label="Your Designation"
                  name="designation"
                  type='text'
                  value={formData.designation}
                  onChange={handleChange}
                  error={formErrors.designation}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />

                <FormField
                  label="Email of Supervisor"
                  name="supervisor_email"
                  type='text'
                  value={formData.supervisor_email}
                  onChange={handleChange}
                  error={formErrors.supervisor_email}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />

                {/* <FormField
              label="Country Code"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              error={formErrors.pincode}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
            /> */}

                <FormField
                  label=" Please provide details (tenure of gap) and reasons, if there has been a gap of more than 1 month between
                  two consecutive employments?"
                  name="gap_details"
                  value={formData.gap_details}
                  onChange={handleChange}
                  type='text'
                  error={formErrors.gap_details}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  cssInput5="descInput"
                  placeholder="Enter Details"
                  isTextarea={true}
                  rows={7}
                  cols={52}
                  readOnly={!isEditMode}
                  required={true}
                />
                
                <FormField
                  label="UAN Number"
                  name="uan_no"
                  type='number'
                  value={formData.uan_no}
                  onChange={handleChange}
                  error={formErrors.uan_no}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="UAN Number"
                  required={true}
                />
              </div>

              <div className={styles.subDiv2}>
                <FormField
                  label="Organization Name"
                  type='text'
                  name="organization_name"
                  value={formData.organization_name}
                  onChange={handleChange}
                  error={formErrors.organization_name}
                  // cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />

                {/* <FormField
                  label="Tenure"
                  type='text'
                  name="tenure"
                  value={formData.tenure}
                  onChange={handleChange}
                  error={formErrors.tenure}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                /> */}

                <FormField
                  label="Street address of organization"
                  name="street_address"
                  type='text'
                  value={formData.street_address}
                  onChange={handleChange}
                  error={formErrors.street_address}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
                <div className={styles.subDiv}>
                  <AutocompleteInput
                    label="Country"
                    name="country"
                    inputlabel="Select"
                    value={formData.country}
                    error={formErrors.country}
                    handleChange={handleChange}
                    options={countries}
                    // selectCss1="subDiv2"
                    selectCss2="label"
                    selectCss3="textField2"
                    readOnly={!isEditMode}
                  />
                  {
                    formData.country !== 'India' ? (
                      <FormField
                        label="State"
                        name="state"
                        type='text'
                        value={formData.state}
                        onChange={handleChange}
                        error={formErrors.state}
                        cssInput1="subDiv2"
                        cssInput2="label"
                        placeholder="Enter Details"
                        readOnly={!isEditMode}
                        required={true}
                      />
                    ) : (
                      <AutocompleteInput
                        label="State"
                        name="state"
                        inputlabel="Select"
                        value={formData.state}
                        error={formErrors.state}
                        handleChange={handleChange}
                        options={union}
                        // selectCss1="subDiv2"
                        selectCss2="label"
                        selectCss3="textField2"
                        readOnly={!isEditMode}
                      />
                    )

                  }

                </div>
                <div className={styles.subDiv}>
                  {
                    formData.country !== 'India' ? (
                      <FormField
                        label="City"
                        name="city"
                        type='text'
                        value={formData.city}
                        onChange={handleChange}
                        error={formErrors.city}
                        cssInput1="subDiv2"
                        cssInput2="label"
                        placeholder="Enter Details"
                        readOnly={!isEditMode}
                        required={true}
                      />
                    ) : (
                      <AutocompleteInput
                        label="City"
                        name="city"
                        inputlabel="Select"
                        value={formData.city}
                        error={formErrors.city}
                        handleChange={handleChange}
                        options={cities.find((state) => state.state_name === formData.state)?.city_names || []}
                        // selectCss1="subDiv2"
                        selectCss2="label"
                        selectCss3="textField2"
                        readOnly={!isEditMode}
                      />
                    )
                  }

                  <FormField
                    label="Zip code"
                    name="pincode"
                    type='number'
                    value={formData.pincode}
                    onChange={handleChange}
                    error={formErrors.pincode}
                    // cssInput1="subDiv2"
                    cssInput2="label"
                    // cssInput3="textField2"

                    placeholder="Enter Details"
                    readOnly={!isEditMode}
                    required={true}
                  />


                </div>


                <FormField
                  label="Name of Supervisor"
                  name="supervisor_name"
                  type='text'
                  value={formData.supervisor_name}
                  onChange={handleChange}
                  error={formErrors.supervisor_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter Details"
                  required={true}
                />

                <FormField
                  label="Phone Number of Supervisor"
                  name="supervisor_phone_number"
                  type='phone'
                  value={formData.supervisor_phone_number}
                  onChange={handleChange}
                  error={formErrors.supervisor_phone_number}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"

                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />

                <FormField
                  label="Name of HR"
                  name="hr_name"
                  type='text'
                  value={formData.hr_name}
                  onChange={handleChange}
                  error={formErrors.hr_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter Details"
                  required={true}
                />

                <FormField
                  label="Email of HR"
                  name="hr_email"
                  value={formData.hr_email}
                  onChange={handleChange}
                  error={formErrors.hr_email}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  type='text'
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter Details"
                  required={true}
                />
                <FormField
                  label="HR Phone Number"
                  name="hr_phone_number"
                  type='phone'
                  value={formData.hr_phone_number}
                  onChange={handleChange}
                  error={formErrors.hr_phone_number}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter HR Phone Number"
                  required={true}
                />
                <FormField
                  label={formData.is_fresher === 'no' ? "Referee Company Name 1" : "University Name"}
                  name={formData.is_fresher === 'no' ? "referee_company_name" : "referee_university_name"}
                  type='text'
                  value={formData.is_fresher === 'no' ? formData.referee_company_name : formData.referee_university_name}
                  onChange={handleChange}
                  error={formData.is_fresher === 'no' ? formErrors.referee_company_name : formErrors.referee_university_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  cssInput3="subDiv2"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
              </div>
            </div>
          </>
        )}
        <div className={styles.subDiv}>
          <FormField
            label={formData.is_fresher === 'no' ? "Professional Referee Name 1" : "University Referee Name"}
            name="referee_name"
            type='text'
            value={formData.referee_name}
            onChange={handleChange}
            error={formErrors.referee_name}
            cssInput1="subDiv2"
            cssInput2="label"
            placeholder="Enter Details"
            readOnly={!isEditMode}
            required={true}
          />
          <FormField
            label={formData.is_fresher === 'no' ? "Referee Contact Details 1" : "University Referee Contact Details"}
            name="referee_contact"
            type='phone'
            value={formData.referee_contact}
            onChange={handleChange}
            error={formErrors.referee_contact}
            cssInput1="subDiv2"
            cssInput2="label"
            placeholder="Enter Details"
            readOnly={!isEditMode}
            required={true}
          />
        </div>
        
        <div className={styles.subDiv}>
          <FormField
            label={formData.is_fresher === 'no' ? "Referee Email Id 1" : "University Referee Email Id"}
            name="referee_email"
            type='text'
            value={formData.referee_email}
            onChange={handleChange}
            error={formErrors.referee_email}
            cssInput1="subDiv2"
            cssInput2="label"
            placeholder="Enter Details"
            readOnly={!isEditMode}
            required={true}
          />
          {formData.is_fresher === 'no' && (
          <FormField
            label={formData.is_fresher === 'no' ? "Referee Company Name 2" : "University Name"}
            name={formData.is_fresher === 'no' ? "referee_company_name2" : "referee_university_name"}
            type='text'
            value={formData.is_fresher === 'no' ? formData.referee_company_name2 : formData.referee_university_name}
            onChange={handleChange}
            error={formData.is_fresher === 'no' ? formErrors.referee_company_name2 : formErrors.referee_university_name}
            cssInput1="subDiv2"
            cssInput2="label"
            placeholder="Enter Details"
            readOnly={!isEditMode}
            required={true}
          />)}
          {formData.is_fresher === 'no' && (
            <FormField
            label={formData.is_fresher === 'no' ? "Professional Referee Name 2" : "University Referee Name"}
            name="referee_name2"
            type='text'
            value={formData.referee_name2}
            onChange={handleChange}
            error={formErrors.referee_name2}
            cssInput1="subDiv2"
            cssInput2="label"
            placeholder="Enter Details"
            readOnly={!isEditMode}
            required={true}
          />
          )}
          {formData.is_fresher === 'no' && (
          <FormField
            label={formData.is_fresher === 'no' ? "Referee Contact Details 2" : "University Referee Contact Details"}
            name="referee_contact2"
            type='phone'
            value={formData.referee_contact2}
            onChange={handleChange}
            error={formErrors.referee_contact2}
            cssInput1="subDiv2"
            cssInput2="label"
            placeholder="Enter Details"
            readOnly={!isEditMode}
            required={true}
          />)}
          {formData.is_fresher === 'no' && (
          <FormField
            label={formData.is_fresher === 'no' ? "Referee Email Id 2" : "University Referee Email Id"}
            name="referee_email2"
            type='text'
            value={formData.referee_email2}
            onChange={handleChange}
            error={formErrors.referee_email2}
            cssInput1="subDiv2"
            cssInput2="label"
            placeholder="Enter Details"
            readOnly={!isEditMode}
            required={true}
          />)}
          { formData.is_fresher === 'no' ? 
                <FormSelect
                  label="Do you want to add another employer details ?"
                  name="prev_employment"
                  inputlabel="Select"
                  value={formData.prev_employment}
                  error={formErrors.prev_employment}
                  handleChange={handleChange}
                  options={isFresher}
                  selectCss1="subDiv2"
                  selectCss2="label"
                  readOnly={!isEditMode}
                  required={true}
                />:
                <FormField
                  label={formData.is_fresher === 'no' ? "Referee Company Name" : "University Name"}
                  name={formData.is_fresher === 'no' ? "referee_company_name" : "referee_university_name"}
                  type='text'
                  value={formData.is_fresher === 'no' ? formData.referee_company_name : formData.referee_university_name}
                  onChange={handleChange}
                  error={formData.is_fresher === 'no' ? formErrors.referee_company_name : formErrors.referee_university_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
              }
          



        </div>
        {formData.prev_employment === 'yes' && (
          <>
            <div
              className={styles.fieldDiv}>
              <div className={styles.hrDiv}>
                <div className={styles.subDiv} style={{ paddingBottom: '10px' }}>
                  <div>
                    <p className={styles.label}>
                    Employment From<span style={{ color: 'red' }}>*</span>
                    </p>
                    <div className={styles.textField}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div style={{ backgroundColor: '#fff' }}>
                            <DatePicker
                              name="prev_start_date"
                              value={dayjs(formData.prev_start_date)}
                              className="custom-date-picker"
                              onChange={handlePrevStartDateChange}
                              readOnly={!isEditMode}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div>
                    <p className={styles.label}>
                      Employment To<span style={{ color: 'red' }}>*</span>
                    </p>
                    <div className={styles.textField}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div style={{ backgroundColor: '#fff' }}>
                            <DatePicker
                              name="prev_end_date"
                              value={dayjs(formData.prev_end_date)}
                              className="custom-date-picker"
                              onChange={handlePrevEndDateChange}
                              readOnly={!isEditMode}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>



                <FormField
                  label="Employee code"
                  name="prev_employee_id"
                  type='text'
                  value={formData.prev_employee_id}
                  onChange={handleChange}
                  error={formErrors.prev_employee_id}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
                {/* <FormField
                  label="Organization"
                  type='text'
                  name="prev_organization_name"
                  value={formData.prev_organization_name}
                  onChange={handleChange}
                  error={formErrors.prev_organization_name}
                  // cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                /> */}
                <FormField
                  label="Email of Supervisor"
                  name="prev_supervisor_email"
                  type='text'
                  value={formData.prev_supervisor_email}
                  onChange={handleChange}
                  error={formErrors.prev_supervisor_email}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
                <FormField
                  label="Name of Supervisor"
                  name="prev_supervisor_name"
                  type='text'
                  value={formData.prev_supervisor_name}
                  onChange={handleChange}
                  error={formErrors.prev_supervisor_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter Details"
                  required={true}
                />

                <FormField
                  label="Phone Number of Supervisor"
                  name="prev_supervisor_phone_number"
                  type='phone'
                  value={formData.prev_supervisor_phone_number}
                  onChange={handleChange}
                  error={formErrors.prev_supervisor_phone_number}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"

                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
                <div className={styles.subDiv}>
                  {
                    formData.country !== 'India' ? (
                      <FormField
                        label="City"
                        name="prev_city"
                        type='text'
                        value={formData.prev_city}
                        onChange={handleChange}
                        error={formErrors.prev_city}
                        cssInput1="subDiv2"
                        cssInput2="label"
                        placeholder="Enter Details"
                        readOnly={!isEditMode}
                        required={true}
                      />
                    ) : (
                      <AutocompleteInput
                        label="City"
                        name="prev_city"
                        inputlabel="Select"
                        value={formData.prev_city}
                        error={formErrors.prev_city}
                        handleChange={handleChange}
                        options={cities.find((state) => state.state_name === formData.state)?.city_names || []}
                        // selectCss1="subDiv2"
                        selectCss2="label"
                        selectCss3="textField2"
                        readOnly={!isEditMode}
                      />
                    )
                  }

                  <FormField
                    label="Zip code"
                    name="prev_pin"
                    type='number'
                    value={formData.prev_pin}
                    onChange={handleChange}
                    error={formErrors.prev_pin}
                    // cssInput1="subDiv2"
                    cssInput2="label"
                    // cssInput3="textField2"

                    placeholder="Enter Details"
                    readOnly={!isEditMode}
                    required={true}
                  />


                </div>


              </div>

              <div className={styles.subDiv2}>
                <FormField
                  label="Name of the company"
                  name="prev_company_name"
                  type='text'
                  value={formData.prev_company_name}
                  onChange={handleChange}
                  error={formErrors.prev_company_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
                <FormField
                  label="Your Designation"
                  name="prev_designation"
                  type='text'
                  value={formData.prev_designation}
                  onChange={handleChange}
                  error={formErrors.prev_designation}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />

                <FormField
                  label="Name of HR"
                  name="prev_hr_name"
                  type='text'
                  value={formData.prev_hr_name}
                  onChange={handleChange}
                  error={formErrors.prev_hr_name}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter Details"
                  required={true}
                />

                <FormField
                  label="Email of HR"
                  name="prev_hr_email"
                  value={formData.prev_hr_email}
                  onChange={handleChange}
                  error={formErrors.prev_hr_email}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  type='text'
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter Details"
                  required={true}
                />
                <FormField
                  label="HR Phone Number"
                  name="prev_hr_phone_number"
                  type='phone'
                  value={formData.prev_hr_phone_number}
                  onChange={handleChange}
                  error={formErrors.prev_hr_phone_number}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter HR Phone Number"
                  required={true}
                />

                <div className={styles.subDiv}>
                  <AutocompleteInput
                    label="Country"
                    name="prev_country"
                    inputlabel="Select"
                    value={formData.prev_country}
                    error={formErrors.prev_country}
                    handleChange={handleChange}
                    options={countries}
                    // selectCss1="subDiv2"
                    selectCss2="label"
                    selectCss3="textField2"
                    readOnly={!isEditMode}
                  />
                  {
                    formData.country !== 'India' ? (
                      <FormField
                        label="State"
                        name="prev_state"
                        type='text'
                        value={formData.prev_state}
                        onChange={handleChange}
                        error={formErrors.prev_state}
                        cssInput1="subDiv2"
                        cssInput2="label"
                        placeholder="Enter Details"
                        readOnly={!isEditMode}
                        required={true}
                      />
                    ) : (
                      <AutocompleteInput
                        label="State"
                        name="prev_state"
                        inputlabel="Select"
                        value={formData.prev_state}
                        error={formErrors.prev_state}
                        handleChange={handleChange}
                        options={union}
                        // selectCss1="subDiv2"
                        selectCss2="label"
                        selectCss3="textField2"
                        readOnly={!isEditMode}
                      />
                    )

                  }

                </div>


              </div>


            </div>
            <div className={styles.oneEmpty}>
              <FormSelect
                label="Available Document Type"
                name="prev_experienced_doc_type"
                inputlabel="Select"
                value={formData.prev_experienced_doc_type}
                error={formErrors.prev_experienced_doc_type}
                handleChange={handleChange}
                options={docType}
                // selectCss1="subDiv2"
                selectCss2="label"
                selectCss3="textField2"
                readOnly={!isEditMode}
                required={true}
              />

            </div>
            <div
              className={styles.fieldDiv}
              style={{ paddingBottom: '10px', alignItems:'center'}}
            >

              <FileInput
                label={formData.prev_experienced_doc_type === "Experienced Letter" ? "Experience Letter" : "Service Letter"}
                // name={formData.prev_experienced_doc_type === "Experienced Letter" ? "Experience Letter" : "Service Letter"}
                name={`${fullName} ${formData.prev_experienced_doc_type === "Experienced Letter" ? "Experience Letter" : "Service Letter"}`}
                file={fileInputData.service_doc}
                onChange={handleFileInputChange}
                id="service_doc"
                required
                fileCss1="subDiv2"
                fileCss2="label"
                fileCss3="fileDiv2"
                fileCss4="fileDiv1"
                readOnly={!isEditMode}

              />
                <FormSelect
                  label="Do you want to add one more employer details?"
                  name="prev_employment2"
                  inputlabel="Select"
                  value={formData.prev_employment2}
                  error={formErrors.prev_employment2}
                  handleChange={handleChange}
                  options={isFresher}
                  selectCss1="subDiv2"
                  selectCss2="label"
                  readOnly={!isEditMode}
                  required={true}
                />
            </div>
          </>
        )}
        {formData.prev_employment2 === 'yes' && (
          <>
            <div
              className={styles.fieldDiv}>
              <div className={styles.hrDiv}>
                <div className={styles.subDiv} style={{ paddingBottom: '10px' }}>
                  <div>
                    <p className={styles.label}>
                    Employment From<span style={{ color: 'red' }}>*</span>
                    </p>
                    <div className={styles.textField}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div style={{ backgroundColor: '#fff' }}>
                            <DatePicker
                              name="prev_start_date2"
                              value={dayjs(formData.prev_start_date2)}
                              className="custom-date-picker"
                              onChange={handlePrevStartDateChange}
                              readOnly={!isEditMode}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div>
                    <p className={styles.label}>
                      Employment To<span style={{ color: 'red' }}>*</span>
                    </p>
                    <div className={styles.textField}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <div style={{ backgroundColor: '#fff' }}>
                            <DatePicker
                              name="prev_end_date2"
                              value={dayjs(formData.prev_end_date2)}
                              className="custom-date-picker"
                              onChange={handlePrevEndDateChange}
                              readOnly={!isEditMode}
                            />
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>



                <FormField
                  label="Employee code"
                  name="prev_employee_id2"
                  type='text'
                  value={formData.prev_employee_id2}
                  onChange={handleChange}
                  error={formErrors.prev_employee_id2}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
                {/* <FormField
                  label="Organization"
                  type='text'
                  name="prev_organization_name"
                  value={formData.prev_organization_name}
                  onChange={handleChange}
                  error={formErrors.prev_organization_name}
                  // cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                /> */}
                <FormField
                  label="Email of Supervisor"
                  name="prev_supervisor_email2"
                  type='text'
                  value={formData.prev_supervisor_email2}
                  onChange={handleChange}
                  error={formErrors.prev_supervisor_email2}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
                <FormField
                  label="Name of Supervisor"
                  name="prev_supervisor_name2"
                  type='text'
                  value={formData.prev_supervisor_name2}
                  onChange={handleChange}
                  error={formErrors.prev_supervisor_name2}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter Details"
                  required={true}
                />

                <FormField
                  label="Phone Number of Supervisor"
                  name="prev_supervisor_phone_number2"
                  type='phone'
                  value={formData.prev_supervisor_phone_number2}
                  onChange={handleChange}
                  error={formErrors.prev_supervisor_phone_number2}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"

                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
                <div className={styles.subDiv}>
                  {
                    formData.country !== 'India' ? (
                      <FormField
                        label="City"
                        name="prev_city2"
                        type='text'
                        value={formData.prev_city2}
                        onChange={handleChange}
                        error={formErrors.prev_city2}
                        cssInput1="subDiv2"
                        cssInput2="label"
                        placeholder="Enter Details"
                        readOnly={!isEditMode}
                        required={true}
                      />
                    ) : (
                      <AutocompleteInput
                        label="City"
                        name="prev_city2"
                        inputlabel="Select"
                        value={formData.prev_city2}
                        error={formErrors.prev_city2}
                        handleChange={handleChange}
                        options={cities.find((state) => state.state_name === formData.state)?.city_names || []}
                        // selectCss1="subDiv2"
                        selectCss2="label"
                        selectCss3="textField2"
                        readOnly={!isEditMode}
                      />
                    )
                  }

                  <FormField
                    label="Zip code"
                    name="prev_pin2"
                    type='number'
                    value={formData.prev_pin2}
                    onChange={handleChange}
                    error={formErrors.prev_pin2}
                    // cssInput1="subDiv2"
                    cssInput2="label"
                    // cssInput3="textField2"

                    placeholder="Enter Details"
                    readOnly={!isEditMode}
                    required={true}
                  />


                </div>


              </div>

              <div className={styles.subDiv2}>
                <FormField
                  label="Name of the company"
                  name="prev_company_name2"
                  type='text'
                  value={formData.prev_company_name2}
                  onChange={handleChange}
                  error={formErrors.prev_company_name2}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />
                <FormField
                  label="Your Designation"
                  name="prev_designation2"
                  type='text'
                  value={formData.prev_designation2}
                  onChange={handleChange}
                  error={formErrors.prev_designation2}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />

                <FormField
                  label="Name of HR"
                  name="prev_hr_name2"
                  type='text'
                  value={formData.prev_hr_name2}
                  onChange={handleChange}
                  error={formErrors.prev_hr_name2}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter Details"
                  required={true}
                />

                <FormField
                  label="Email of HR"
                  name="prev_hr_email2"
                  value={formData.prev_hr_email2}
                  onChange={handleChange}
                  error={formErrors.prev_hr_email2}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  type='text'
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter Details"
                  required={true}
                />
                <FormField
                  label="HR Phone Number"
                  name="prev_hr_phone_number2"
                  type='phone'
                  value={formData.prev_hr_phone_number2}
                  onChange={handleChange}
                  error={formErrors.prev_hr_phone_number2}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  // cssInput3="textField1"
                  readOnly={!isEditMode}
                  placeholder="Enter HR Phone Number"
                  required={true}
                />

                <div className={styles.subDiv}>
                  <AutocompleteInput
                    label="Country"
                    name="prev_country2"
                    inputlabel="Select"
                    value={formData.prev_country2}
                    error={formErrors.prev_country2}
                    handleChange={handleChange}
                    options={countries}
                    // selectCss1="subDiv2"
                    selectCss2="label"
                    selectCss3="textField2"
                    readOnly={!isEditMode}
                  />
                  {
                    formData.country !== 'India' ? (
                      <FormField
                        label="State"
                        name="prev_state2"
                        type='text'
                        value={formData.prev_state2}
                        onChange={handleChange}
                        error={formErrors.prev_state2}
                        cssInput1="subDiv2"
                        cssInput2="label"
                        placeholder="Enter Details"
                        readOnly={!isEditMode}
                        required={true}
                      />
                    ) : (
                      <AutocompleteInput
                        label="State"
                        name="prev_state2"
                        inputlabel="Select"
                        value={formData.prev_state2}
                        error={formErrors.prev_state2}
                        handleChange={handleChange}
                        options={union}
                        // selectCss1="subDiv2"
                        selectCss2="label"
                        selectCss3="textField2"
                        readOnly={!isEditMode}
                      />
                    )

                  }

                </div>


              </div>


            </div>
            <div className={styles.oneEmpty}>
              <FormSelect
                label="Available Document Type"
                name="prev_experienced_doc_type2"
                inputlabel="Select"
                value={formData.prev_experienced_doc_type2}
                error={formErrors.prev_experienced_doc_type2}
                handleChange={handleChange}
                options={docType}
                // selectCss1="subDiv2"
                selectCss2="label"
                selectCss3="textField2"
                readOnly={!isEditMode}
                required={true}
              />

            </div>
            <div
              className={styles.fieldDiv}
              style={{ paddingBottom: '10px', alignItems:'center'}}
            >

              <FileInput
                label={formData.prev_experienced_doc_type2 === "Experienced Letter" ? "Experience Letter" : "Service Letter"}
                // name={formData.prev_experienced_doc_type === "Experienced Letter" ? "Experience Letter" : "Service Letter"}
                name={`${fullName} ${formData.prev_experienced_doc_type2 === "Experienced Letter" ? "Experience Letter" : "Service Letter"}`}
                file={fileInputData.service_doc2}
                onChange={handleFileInputChange}
                id="service_doc2"
                required
                fileCss1="subDiv2"
                fileCss2="label"
                fileCss3="fileDiv2"
                fileCss4="fileDiv1"
                readOnly={!isEditMode}

              />
            </div>
          </>
        )}

        {/* <div className={styles.fieldDiv}>
          <div>
            <FormSelect
              label="Do you have OCI or Visa"
              name="organization_name"
              inputlabel="Select"
              value={formData.organization_name}
              error={formErrors.organization_name}
              handleChange={handleChange}
              options={Educati}
              selectCss1="subDiv2"
              selectCss2="label"
              selectCss3="textField2"
            />
          </div>

          <div></div>
        </div>

        <div className={styles.fieldDiv}>
          <div>
            <FormField
              label="Country of origin"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              error={formErrors.pincode}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
            />

            <div className={styles.subDiv}>
              <div>
                <p className={styles.label}>
                  OCI/ VISA Date of issue<span style={{ color: 'red' }}>*</span>
                </p>
                <div className={styles.textField}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div style={{ backgroundColor: '#fff' }}>
                        <DatePicker
                          name="start_date"
                          value={dayjs(formData.start_date)}
                          className="custom-date-picker"
                          onChange={handleStartDateChange}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>

          <div>
            <FormField
              label="Email of HR"
              name="hr_email"
              value={formData.hr_email}
              onChange={handleChange}
              error={formErrors.hr_email}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
            />

            <div className={styles.subDiv}>
              <div>
                <p className={styles.label}>
                  OCI/VISA Valid From<span style={{ color: 'red' }}>*</span>
                </p>
                <div className={styles.textField}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div style={{ backgroundColor: '#fff' }}>
                        <DatePicker
                          name="end_date"
                          value={dayjs(formData.end_date)}
                          className="custom-date-picker"
                          onChange={handleEndDateChange}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <p className={styles.label}>
                  OCI/ VISA Valid To<span style={{ color: 'red' }}>*</span>
                </p>
                <div className={styles.textField}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div style={{ backgroundColor: '#fff' }}>
                        <DatePicker
                          name="start_date"
                          value={dayjs(formData.start_date)}
                          className="custom-date-picker"
                          onChange={handleStartDateChange}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {persona !== 'admin' && (
          <div className={styles.btnDiv2}>
            <div></div>
            <div className={styles.subbtnDiv1}>
              <div className={styles.btn}>
                <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                  Submit document
                </Button>
              </div>
            </div>
          </div>
        )}
        {
          persona === 'admin' && (
            <div className={styles.btnDiv2}>
              <div></div>
              <div className={styles.subbtnDiv1} >
                <div className={styles.btn} style={{ display: "flex", gap: "15px" }}>
                  <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                    Update document
                  </Button>
                  <Button type="submit" variant="contained"  color="primary" onClick={toggleEditMode}>
                    Edit document
                  </Button>
                </div>
              </div>
            </div>
          )
        }
      </form>
      <PhoneMenu />
    </>
  );
};

export default WorkExperienceForm;
