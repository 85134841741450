import React, { useState, FormEvent, useRef, useEffect } from 'react';
import styles from './Documents.module.css';
import { Button, SelectChangeEvent } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


import SubmitPopup from 'project-name/components/CandidateSecion/Global/Popup/SubmitPopup';

import Modal from '@mui/material/Modal';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';

import FormSelect from './GlobalFormsField/FormSelect';
import FormField from './GlobalFormsField/FormField';
import FileInput from './GlobalFormsField/FileInput';
import apiClient from 'project-name/api/apiClient';
import { getStatusByName, validateAadhaarNumber, validatePanNumber, validatePhoneNumber } from 'project-name/util/util';
import { useLocation } from 'react-router-dom';
import { M } from 'vite/dist/node/types.d-aGj9QkWt';
interface PersonalDetailsFormProps {
  onSubmit?: (formData: any) => void;
  persona?: string;
  formData?: any;
}
interface FormData {
  title: string;
  d_o_b: Dayjs | string; // Change type to Dayjs | string
  gender: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  aadhaar_no: string;
  em_contact_name: string;
  ecpn: string;
  company_work_relation: string;

  shirt_size: string;
  fav_beverage: string;
  em_country_code: string;
  phone_no: string;
  em_contact_no: string;
  aadhaar_name: string;
  blood_group: string;
  adhar_photo_1: string;
  adhar_photo_2: string;
  pan_no: string;
  pan_can_doc_id: string;
  vaccine_status: string;
  vacc_can_doc_id: string;
  alt_no: string;
  em_contact_relation: string;
  aadhaar_can_doc_id: string;
  relative_email: string,
  relative_contact_no: string,
  adhar_photo: string,
  pan_photo: string,
  relative_name: string,
  father_name: string,
  mother_name: string,
  father_contact_no: string,
  mother_contact_no: string,
}

interface FileInputData {
  adhaarCardFile?: File | null;
  panCardFile?: File | null;
  vaccineCertificateFile?: File | null;
}

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = ({ persona, formData: form }) => {
  const filteredStatus = getStatusByName("Personal details");
  const navigate = useNavigate();
  const isPending = filteredStatus === "Pending";
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(() => persona === 'admin' ? false : true);
  const adhaarCardInputRef = useRef<HTMLInputElement | null>(null);
  const panCardInputRef = useRef<HTMLInputElement | null>(null);
  const vaccineCertificateInputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [user_id, setUserId] = useState<number>(() => persona === 'admin' ? location.state.user_id : 0);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    d_o_b: dayjs(),
    gender: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    aadhaar_no: '',
    em_contact_name: '',
    ecpn: '',
    company_work_relation: '',
    shirt_size: '',
    fav_beverage: '',
    em_country_code: '',
    aadhaar_name: '',
    phone_no: '',
    em_contact_no: '',
    blood_group: '',
    adhar_photo_1: '',
    adhar_photo_2: '',
    pan_no: '',
    pan_can_doc_id: '',
    vaccine_status: '',
    vacc_can_doc_id: '',
    alt_no: '',
    em_contact_relation: '',
    aadhaar_can_doc_id: '',
    relative_email: '',
    relative_contact_no: '',
    adhar_photo: '',
    pan_photo: '',
    relative_name: '',
    father_name: '',
    mother_name: '',
    father_contact_no: "",
    mother_contact_no: "",
  });
  const toggleEditMode = (e: any) => {

      setIsEditMode((prevMode) => !prevMode);
  
    e.preventDefault();
  };

  // handling form validations and errors

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({
    title: '',
    d_o_b: dayjs(),
    gender: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    aadhaar_no: '',
    em_contact_name: '',
    ecpn: '',
    company_work_relation: '',
    shirt_size: '',
    fav_beverage: '',
    em_country_code: '',
    aadhaar_name: '',
    phone_no: '',
    em_contact_no: '',
    blood_group: '',
    adhar_photo_1: '',
    adhar_photo_2: '',
    pan_no: '',
    pan_can_doc_id: '',
    vaccine_status: '',
    vacc_can_doc_id: '',

    em_contact_relation: '',
    aadhaar_can_doc_id: '',
    relative_email: '',
    relative_contact_no: '',
    relative_name: "",
    adhar_photo: '',
    father_name: '',
    mother_name: '',
    pan_photo: '',
    father_contact_no: "",
    mother_contact_no: "",
  });


  let combinedStatus = false;
  let panStatus = true;
  let adharStatus = true;
  let vacStatus = true;

  if (persona !== 'admin' || persona == null || persona == undefined) {
    const storedDocTypeIdsWithStatus105 = JSON.parse(localStorage.getItem("docTypeIdsWithStatus105") || '[]');
    const checkIfAnyDocTypeIdsExist = (storedDocTypeIds: number[]): boolean => {
      return storedDocTypeIdsWithStatus105.some((docTypeId: number) => storedDocTypeIds.includes(docTypeId));
    };

    panStatus = checkIfAnyDocTypeIdsExist([309]);
    adharStatus = checkIfAnyDocTypeIdsExist([310]);
    vacStatus = checkIfAnyDocTypeIdsExist([312]);
    combinedStatus = panStatus || adharStatus || vacStatus;
  }


  useEffect(() => {
    if (persona === 'admin' && form !== undefined) {
      console.log("formmmmmmm", form);
      const sanitizedForm = { ...form };
      for (const key in sanitizedForm) {
        if (sanitizedForm[key] === null || sanitizedForm[key] === undefined) {
          sanitizedForm[key] = '';
        }
      }
      setFormData(sanitizedForm);
    }
  }, [persona, form])



  // Example form submission handling
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const formValid = validateForm();
    
    

    if (formValid) {
      try {


        const formDataToSend = new FormData();
        // Append files to formData

        // Append adhaarCardFile to formData
        if (fileInputData.adhaarCardFile) {
          formDataToSend.append('aadhar_card', fileInputData.adhaarCardFile);
        }

        if (fileInputData.panCardFile) {
          formDataToSend.append('pan_card', fileInputData.panCardFile);
        }

        if (persona === 'admin') {
          const is_edit = Object.keys(form).length !== 0
          formDataToSend.append('data', JSON.stringify({
            first_name: formData.first_name,
            title: formData.title,
            middle_name: formData.middle_name,
            last_name: formData.last_name,
            d_o_b: formData.d_o_b instanceof dayjs ? formData.d_o_b.format('YYYY-MM-DD') : formData.d_o_b, // Format Dayjs object as string without time
            gender: formData.gender,
            aadhaar_no: formData.aadhaar_no,
            em_contact_name: formData.em_contact_name,
            ecpn: formData.ecpn,
            company_work_relation: formData.company_work_relation,
            shirt_size: formData.shirt_size,
            fav_beverage: formData.fav_beverage,
            em_country_code: formData.em_country_code,
            aadhaar_name: formData.aadhaar_name,
            phone_no: formData.phone_no,
            em_contact_no: formData.em_contact_no,
            blood_group: formData.blood_group,
            father_name: formData.father_name,
            pan_no: formData.pan_no,
            mother_name: formData.mother_name,
            vaccine_status: formData.vaccine_status,
            relative_contact_no: formData.relative_contact_no,
            relative_name: formData.relative_name,
            relative_email: formData.relative_email,
            alt_no: formData.alt_no,
            em_contact_relation: formData.em_contact_relation,
            father_contact_no: formData.father_contact_no,
            mother_contact_no: formData.mother_contact_no,
          }));

          formDataToSend.append('is_edit', (is_edit).toString());
          formDataToSend.append(
            'type',
            'personal_details',
          )

          const response = await apiClient.post(
            `/api/admin/${user_id}/update-additional-candidate-details`,
            formDataToSend,
            {
              withCredentials: true,
            }

          );

          if (response.status !== 200) {

            toast.error("Failed to submit form: ");
            throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);
          }

          const data = response.data;
          setIsEditMode((prevMode) => !prevMode);
          return;

        }

        formDataToSend.append(
          'details',
          JSON.stringify({
            first_name: formData.first_name,
            title: formData.title,
            middle_name: formData.middle_name,
            last_name: formData.last_name,
            d_o_b: formData.d_o_b instanceof dayjs ? formData.d_o_b.format('YYYY-MM-DD') : formData.d_o_b, // Format Dayjs object as string without time
            gender: formData.gender,
            aadhaar_no: formData.aadhaar_no,
            em_contact_name: formData.em_contact_name,
            ecpn: formData.ecpn,
            company_work_relation: formData.company_work_relation,
            shirt_size: formData.shirt_size,

            em_country_code: formData.em_country_code,
            aadhaar_name: formData.aadhaar_name,
            phone_no: formData.phone_no,
            em_contact_no: formData.em_contact_no,
            blood_group: formData.blood_group,
            pan_no: formData.pan_no,
            relative_contact_no: formData.relative_contact_no,
            relative_name: formData.relative_name,
            relative_email: formData.relative_email,
            vaccine_status: formData.vaccine_status,
            alt_no: formData.alt_no,
            father_name: formData.father_name,
            mother_name: formData.mother_name,
            father_contact_no: formData.father_contact_no,
            mother_contact_no: formData.mother_contact_no,
            em_contact_relation: formData.em_contact_relation,
          }),
        );


        const response = await apiClient.post(
          `/api/candidate/upload-personal-details`,
          formDataToSend,
          {
            withCredentials: true,
          }
        );

        if (response.status !== 200) {


          throw new Error(`Failed to submit form: ${response.status} - ${response.statusText}`);

        }


        const data = response.data;

        // Simulated fetch request
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Simulated successful submission
        setShowModal(true);

        // Reset form data after successful submission
        setFormData({
          title: '',
          adhar_photo: '',
          pan_photo: '',
          d_o_b: dayjs(),
          gender: '',
          first_name: '',
          middle_name: '',
          last_name: '',
          aadhaar_no: '',
          em_contact_name: '',
          ecpn: '',
          company_work_relation: '',
          shirt_size: '',
          fav_beverage: '',
          em_country_code: '',
          aadhaar_name: '',
          phone_no: '',
          em_contact_no: '',
          blood_group: '',
          adhar_photo_1: '',
          adhar_photo_2: '',
          pan_no: '',
          pan_can_doc_id: '',
          vaccine_status: '',
          vacc_can_doc_id: '',
          alt_no: '',
          em_contact_relation: '',
          aadhaar_can_doc_id: '',
          relative_email: '',
          relative_contact_no: '',
          relative_name: "",
          father_contact_no: "",
          mother_contact_no: "",
          father_name: "",
          mother_name: "",
        });

        // reset file input field
        setFileInputData({
          adhaarCardFile: null,
          panCardFile: null,
          vaccineCertificateFile: null,
        });

        // Reset file input fields
        if (adhaarCardInputRef.current) adhaarCardInputRef.current.value = '';
        if (panCardInputRef.current) panCardInputRef.current.value = '';

      } catch (error: any) {
        toast.error('Error submitting form:', error);
        console.error('Error submitting form:', error);
        if (persona === 'admin') {
          setIsEditMode((prevMode) => !prevMode);
        }
      }
    } else {
      console.log('Form validation failed');
    }
  };

  const validateForm = () => {
    let formValid = true;
    const errors: {
      title?: string;
      // d_o_b?: Dayjs | string;
      pan_photo?: string;
      adhar_photo?: string;
      gender?: string;
      first_name?: string;
      last_name?: string;
      middle_name?: string;
      aadhaar_name?: string;
      aadhaar_no?: string;
      em_contact_name?: string;
      company_work_relation?: string;
      shirt_size?: string;
      
      em_country_code?: string;
      phone_no?: string;
      em_contact_no?: string;
      blood_group?: string;
      pan_no?: string;
      father_name?: string;
      // mother_name?: string;
    
      aadhaar_photo_1?: string;
      em_contact_relation?: string;
    } = {};
    if (!formData.title.trim()) {
      errors.title = 'Title is required';
      formValid = false;
    }


    if (!formData.gender.trim()) {
      errors.gender = 'Gender is required';
      formValid = false;
    }

    if (!formData.father_name.trim()) {
      errors.father_name = 'Father name is required';
      formValid = false;
    }

    // if (!formData.mother_name.trim()) {
    //   errors.mother_name = 'Mother name is required';
    //   formValid = false;
    // }

    if (!formData.first_name.trim()) {
      errors.first_name = 'First name is required';
      formValid = false;
    }

    if (!formData.last_name.trim()) {
      errors.last_name = 'Last name is required';
      formValid = false;
    }


    if (!formData.aadhaar_name.trim()) {
      errors.aadhaar_name = 'Name on Aadhar card is required';
      formValid = false;
    }

    if (!formData.aadhaar_no.trim()) {
      errors.aadhaar_no = 'Aadhar number is required';
      formValid = false;
    }

    if (formData.aadhaar_no.trim()) {
      const aadhaarError = validateAadhaarNumber(formData.aadhaar_no);
      if (aadhaarError) {
        errors.aadhaar_no = aadhaarError;
        formValid = false;
      }
    }


    if (!formData.em_contact_name.trim()) {
      errors.em_contact_name = 'Emergency contact name is required';
      formValid = false;
    }



   
   


    
   

    if (!formData.company_work_relation.trim()) {
      errors.company_work_relation = 'Company work relation is required';
      formValid = false;
    }

    if (!formData.shirt_size.trim()) {
      errors.shirt_size = 'T-shirt size is required';
      formValid = false;
    }

    



    if (!formData.phone_no.trim()) {
      errors.phone_no = 'Phone number is required';
      formValid = false;
    }

    const phoneContactLe = formData?.phone_no?.slice(0, 2);


    if (formData?.phone_no && formData?.phone_no.length < 10 && Number(phoneContactLe) == 91) {
      errors.phone_no = 'Phone number should be 10 digits';
      formValid = false;

    }




    if (!formData.em_contact_no.trim()) {
      errors.em_contact_no = 'Emergency contact number is required';
      formValid = false;
    }

    const emContactLe = formData?.em_contact_no?.slice(0, 2);


    if (formData?.em_contact_no && formData?.em_contact_no.length < 12 && Number(emContactLe) == 91) {
      errors.em_contact_no = 'Emergency contact number should be 10 digits';
      formValid = false;

    }




    if (!formData.blood_group.trim()) {
      errors.blood_group = 'Blood group is required';
      formValid = false;
    }

    if (!formData.pan_no.trim()) {
      errors.pan_no = 'PAN card number is required';
      formValid = false;
    }

    if (formData.pan_no.trim()) {
      const panError = validatePanNumber(formData.pan_no);
      if (panError) {
        errors.pan_no = panError;
        formValid = false;
      }
    }


    if (!fileInputData.adhaarCardFile) {
      errors.adhar_photo = 'Aadhaar card photo is required';
      formValid = false;
    }
    if (!fileInputData.panCardFile) {
      errors.pan_photo = 'Pan card photo is required';
      formValid = false;
    }

    if (!formData.em_contact_relation.trim()) {
      errors.em_contact_relation = 'Emergency contact relation is required';
      formValid = false;
    }

    setFormErrors(errors);
    return formValid;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => {
    const { name, value } = event.target;
    if (name === 'company_work_relation' && value == 'yes') {
      setPopupOpen(true);
    }
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        [name]: value,
      };
      return newData;
    });

  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    setFormData((prevData) => ({
      ...prevData,
      d_o_b: date || dayjs(), // Use current date as default if date is null
    }));
  };

  const [fileInputData, setFileInputData] = useState<FileInputData>({
    adhaarCardFile: null,
    panCardFile: null,
    vaccineCertificateFile: null,
  });

  // Function to handle file input change
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;



    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();

      if (fileExtension !== 'pdf' && target.id === "adhaarCardInput") {
        alert('Only PDF files are allowed.');
        return;
      }
      // Process the files based on input type
      switch (target.id) {
        case 'adhaarCardInput':
          setFileInputData((prevData) => ({
            ...prevData,
            adhaarCardFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'panCardInput':
          setFileInputData((prevData) => ({
            ...prevData,
            panCardFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'vaccineCertificateInput':
          setFileInputData((prevData) => ({
            ...prevData,
            vaccineCertificateFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        default:
          break;
      }
    }
  };
  

  // submit popup
  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/')

  };

  const title = [
    {
      value: 'mr',
      label: 'Mr.',
    },
    {
      value: 'ms',
      label: `Ms.`,
    },
    {
      value: 'mrs',
      label: `Mrs.`,
    },
  ];

  const gender = [
    {
      value: 'm',
      label: 'Male',
    },
    {
      value: 'f',
      label: 'Female',
    },
    {
      value: 'non',
      label: 'Not Prefer to Say',
    },
  ];

  const blood_group = [
    {
      value: 'A+',
      label: 'A positive (A+)',
    },
    {
      value: 'A-',
      label: 'A negative (A-)',
    },
    {
      value: 'B+',
      label: 'B positive (B+)',
    },
    {
      value: 'B-',
      label: 'B negative (B-).',
    },
    {
      value: 'AB+',
      label: 'AB positive (AB+)',
    },
    {
      value: 'AB-',
      label: 'AB negative (AB-)',
    },
    {
      value: 'O+',
      label: 'O positive (O+)',
    },
    {
      value: 'O-',
      label: 'O negative (O-)',
    }
  ];

  const relatives = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  const tshirt = [
    {
      value: 'xs',
      label: 'XS',
    },
    {
      value: 's',
      label: 'S',
    },
    {
      value: 'm',
      label: 'M',
    },
    {
      value: 'l',
      label: 'L',
    },
    {
      value: 'xl',
      label: 'XL',
    },
    {
      value: 'xxl',
      label: 'XXL',
    },
    {
      value: 'xxxl',
      label: 'XXXL',
    },
  ];
  const fullName = sessionStorage.getItem("candidateName")
  return (
    <>
      {/* Modal */}
      {showModal && (
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SubmitPopup handleClose={handleCloseModal} />
        </Modal>
      )}
      <form className={styles.mAttachDiv}>
        {/* <div className={styles.fieldDiv}> */}
        <div className={` ${combinedStatus ? styles.disabled : ''}`}>

          <div>
            <div className={styles.subDiv}>
              <FormSelect
                label="Title"
                name="title"
                inputlabel="Select"
                value={formData.title}
                error={formErrors.title}
                handleChange={handleChange}
                options={title}
                selectCss1=""
                selectCss2="label"
                selectCss3="textField2"
                readOnly={!isEditMode}
                required={true}
              />

              <FormSelect
                label="Gender"
                name="gender"
                inputlabel="Select"
                value={formData.gender}
                error={formErrors.gender}
                handleChange={handleChange}
                options={gender}
                selectCss1=""
                selectCss2="label"
                selectCss3="textField2"
                readOnly={!isEditMode}
                required={true}


              />
            </div>

            <FormField
              label="First name"
              name="first_name"
              type='text'
              value={formData.first_name}
              onChange={handleChange}
              error={formErrors.first_name}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
              required={true}
            />

            <div className={styles.subDiv}>
              <FormField
                label="Middle name"
                name="middle_name"
                type='text'
                value={formData.middle_name}
                onChange={handleChange}
                error={formErrors.middle_name}
                cssInput1=""
                cssInput2="label"
                placeholder="Enter Details"
                readOnly={!isEditMode}
                required={false}
              />

              <FormField
                label="Last name"
                name="last_name"
                type='text'
                value={formData.last_name}
                onChange={handleChange}
                error={formErrors.last_name}
                cssInput1=""
                cssInput2="label"
                placeholder="Enter Details"
                readOnly={!isEditMode}
                required={true}
              />
            </div>
                <div className={styles.subDiv}>
                  <FormField
                    label="Father's name"
                    name="father_name"
                    type='text'
                    value={formData.father_name}
                    onChange={handleChange}
                    error={formErrors.father_name}
                    cssInput1="subDiv2"
                    cssInput2="label"
                    placeholder="Enter Details"
                    readOnly={!isEditMode}
                    required={true}
                  />
                  <FormSelect
                    label="T shirt size"
                    name="shirt_size"
                    inputlabel="Select"
                    value={formData.shirt_size}
                    error={formErrors.shirt_size}
                    handleChange={handleChange}
                    options={tshirt}
                    selectCss2="label"
                    selectCss3=""
                    readOnly={!isEditMode}
                    required={true}
                  />
            </div>
            <div className={styles.subDiv}>
              
              {/* <FormField
                label="Mother's name"
                name="mother_name"
                type='text'
                value={formData.mother_name}
                onChange={handleChange}
                error={formErrors.mother_name}
                cssInput1=""
                cssInput2="label"
                placeholder="Enter Details"
                readOnly={!isEditMode}
                required={true}
              /> */}
            </div>

           
            <div className={styles.subDiv}>
              <div>
                <p className={styles.label} style={{ whiteSpace: 'nowrap' }}>
                  Date of birth<span style={{ color: 'red' }}>*</span>
                  {/* <span style={{ color: "black", fontWeight: 500, fontSize: "12px" }}>
                    (its valid for 18 years above only)
                  </span> */}
                </p>

                <div className={styles.textField}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <div style={{ backgroundColor: '#fff' }}>
                        <DatePicker
                          name="d_o_b"
                          value={dayjs(formData.d_o_b)}
                          className="custom-date-picker"
                          onChange={handleDateChange}
                          disabled={!isEditMode}
                          maxDate={dayjs().subtract(18, 'year')}

                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>

                  {/* {formErrors.d_o_b && <div className="error">{formErrors.d_o_b.toString()}</div>} */}
                </div>
              </div>

              <FormSelect
                label="Blood group"
                name="blood_group"
                inputlabel="Select"
                value={formData.blood_group}
                error={formErrors.blood_group}
                handleChange={handleChange}
                options={blood_group}
                selectCss1=""
                selectCss2="label"
                selectCss3=""
                readOnly={!isEditMode}
                required={true}

              />
            </div>
            <div className={styles.subDiv}>
              <FormField
                label="Phone number"
                type='phone'
                name="phone_no"
                value={formData.phone_no}
                onChange={handleChange}
                error={formErrors.phone_no}
                cssInput2="label"
                placeholder="Enter Details"
                readOnly={!isEditMode}
                required={true}
              />
              <FormField
                label="Alternate Phone number"
                name="alt_no"
                type='phone'
                value={formData.alt_no}
                onChange={handleChange}
                error={formErrors.alt_no}
                cssInput1="subDiv2 phone"
                cssInput2="label"
                placeholder="Enter Details"
                readOnly={!isEditMode}
                required={false}

              />
            </div>
            <FormField
              label="Aadhaar Number"
              name="aadhaar_no"
              type='number'
              value={formData.aadhaar_no}
              onChange={handleChange}
              error={formErrors.aadhaar_no}
              cssInput1="subDiv2"
              cssInput2="label"
              placeholder="Enter Details"
              readOnly={!isEditMode}
              required={true}
            />
            <FormField
              label="Name on aadhar card"
              name="aadhaar_name"
              type='text'
              value={formData.aadhaar_name}
              onChange={handleChange}
              error={formErrors.aadhaar_name}
              cssInput1="subDiv2"
              cssInput2="label"

              // cssInput3="textField2"
              placeholder="Enter Details"
              readOnly={!isEditMode}
              required={true}
            />

        <div className={` ${!adharStatus && !isPending ? styles.disabled : ''}`}>

          <FileInput
            label="Aadhaar card photo"
           
            name={`${fullName} 's Aadhaar card`}
            file={fileInputData.adhaarCardFile}
            onChange={handleFileInputChange}
            id="adhaarCardInput"
            fileCss1="docDiv1"
            fileCss2="label"
            fileCss3="fileDiv"
            fileCss4="fileDiv1"
            // error={formErrors.adhar_photo}
            readOnly={!isEditMode}
            customSx={{ marginTop: "20px" }}
            required={true}

          />
          <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}>{formErrors.adhar_photo}</p>
        </div>
        <div className={` ${combinedStatus ? styles.disabled : ''}`} style={{paddingTop:'10px'}}>

          <FormField
            label="Pan card number"
           
            name="pan_no"
            type='text'
            value={formData.pan_no}
            onChange={handleChange}
            error={formErrors.pan_no}
            cssInput1=""
            cssInput2="label"
            // cssInput3="textField2"
            placeholder="Enter PAN number "
            readOnly={!isEditMode}
            required={true}
          />
        </div>

        {/* <div className={styles.fieldDiv}> */}
        <div>

          <div className={` ${!panStatus && !isPending ? styles.disabled : ''}`}>


            <FileInput
              label="Pan card photo"
             
              name={`${fullName} 's Pan card`}
              file={fileInputData.panCardFile}
              onChange={handleFileInputChange}
              id="panCardInput"
              fileCss1="subDiv2"
              fileCss2="label"
              fileCss3="fileDiv2"
              fileCss4="fileDiv1"
              readOnly={!isEditMode}
              required={true}
            />
            <p style={{ color: "red", fontSize: "12px", fontWeight: "500" }}> {formErrors.pan_photo}</p>
          </div>
        </div>
            <div className={styles.subDiv}>
              <FormField
                label="Emergency contact name"
                name="em_contact_name"
                type="text"
                value={formData.em_contact_name}
                onChange={handleChange}
                error={formErrors.em_contact_name}
                cssInput1="subDiv2"
                cssInput2="label"
                placeholder="Enter Details"
                readOnly={!isEditMode}
                required={true}
              />
              <FormField
                label="Emergency contact phone number"
                name="em_contact_no"
                type='phone'
                value={formData.em_contact_no}
                onChange={handleChange}
                error={formErrors.em_contact_no}
                cssInput1="subDiv2"
                cssInput2="label"
                placeholder="Enter Details"
                readOnly={!isEditMode}
                required={true}
              />
            </div>
            <FormField
              label="Emergency contact relation with you"
              name="em_contact_relation"
              type='text'
              value={formData.em_contact_relation}
              onChange={handleChange}
              error={formErrors.em_contact_relation}
              cssInput1="subDiv2"
              cssInput2="label"
              // cssInput3="textField2"
              placeholder="Enter Details"
              readOnly={!isEditMode}
              required={true}
            />
            <FormSelect
              label="Do you have relatives working in the company?"
              name="company_work_relation"
              inputlabel="Select"
              value={formData.company_work_relation}
              error={formErrors.company_work_relation}
              handleChange={handleChange}
              options={relatives}
              selectCss1="subDiv2"
              selectCss2="label"
              selectCss3="textField2"
              readOnly={!isEditMode}
              required={true}

            />
            <Dialog open={isPopupOpen} onClose={handlePopupClose} fullWidth>
              <DialogTitle>Relative Details</DialogTitle>
              <DialogContent>
                <FormField
                  type='text'
                  label="Relative's Name"
                  name="relative_name"
                  value={formData.relative_name}
                  onChange={handleChange}
                  error={formErrors.relative_name}
                  cssInput1="subDiv2 phone"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}

                />
                <FormField
                  label="Relative's Email"
                  name="relative_email"
                  type='email'
                  value={formData.relative_email}
                  onChange={handleChange}
                  error={formErrors.relative_email}
                  cssInput1="subDiv2"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}
                />

                <FormField
                  type='phone'
                  label="Relative's Contact"
                  name="relative_contact_no"
                  value={formData.relative_contact_no}
                  onChange={handleChange}
                  error={formErrors.relative_contact_no}
                  cssInput1="subDiv2 phone"
                  cssInput2="label"
                  placeholder="Enter Details"
                  readOnly={!isEditMode}
                  required={true}

                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handlePopupClose}>Save</Button>
              </DialogActions>
            </Dialog>
          </div>

          
            </div>
            
            <div style={{ marginTop: "10px" }}>

            </div>

            

        <div>
          <div className={` ${combinedStatus ? styles.disabled : ''}`}>

            {/* <FormSelect
              label="COVID 19 vaccination"
              name="vaccine_status"
              inputlabel="Select"
              value={formData.vaccine_status}
              error={formErrors.vaccine_status}
              handleChange={handleChange}
              options={relatives}
              selectCss1=""
              selectCss2="label"
              selectCss3="textField2"
              readOnly={!isEditMode}
            /> */}
          </div>

          {/* <div>
              <p className={styles.label}>
                COVID 19 vaccination <span style={{ color: 'red' }}>*</span>
              </p>
              <div className={styles.textField1}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="india"
                    className={styles.input}
                    name="vaccine_status"
                    value={formData.vaccine_status}
                    onChange={handleChange}
                  >
                    {relatives.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.vaccine_status && <div className="error">{formErrors.vaccine_status}</div>}
                </FormControl>
              </div>
            </div> */}
          <div className={` ${!vacStatus && !isPending ? styles.disabled : ''}`}>

            {/* <FileInput
              label="Vaccination certificate"
              file={fileInputData.vaccineCertificateFile}
              onChange={handleFileInputChange}
              id="vaccineCertificateInput"
              fileCss1="subDiv2"
              fileCss2="label"
              fileCss3="fileDiv2"
              fileCss4="fileDiv1"
              readOnly={!isEditMode}
            /> */}
            {persona == 'admin' && (<span></span>)}
          </div>
        </div>
        {/* </div> */}
        {persona !== 'admin' && (
          <div className={styles.btnDiv2}>
            <div></div>
            <div className={styles.subbtnDiv1}>
              <div className={styles.btn}>
                <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                  Submit document
                </Button>

              </div>
            </div>
          </div>)}
        {
          persona === 'admin' && (
            <div className={styles.btnDiv2}>
              <div></div>
              <div className={styles.subbtnDiv1} >
                <div className={styles.btn} style={{ display: "flex", gap: "15px" }}>
                  <Button type="submit" variant="contained" color="primary"  onClick={handleSubmit}>
                    Update document
                  </Button>
                  {!isEditMode && (
                    <Button type="submit" variant="contained" color="primary" onClick={toggleEditMode} >
                      Edit document
                    </Button>
                  )}
                </div>
              </div>
            </div>

          )}


      </form>
    </>
  );
};

export default PersonalDetailsForm;
